import gql from "graphql-tag";
export const roleFragment = gql`
  fragment Role on Role {
    id,
    name,
  }
`;
export const roleLoadFragment = gql`
  fragment RoleLoad on Role {
    ...Role,
    authorizations {
      id,
      kind,
      resourceId,
    }
  }
  ${roleFragment}
`;