import gql from "graphql-tag";
export const pollTotalVoteFragment = gql`
  fragment PollTotalVote on PollTotalVote  { 
    id,
    pollId,
    onlineAgree,
    onlineDisagree,
    onlineAbstain,
    offlineAgree,
    offlineDisagree,
    offlineAbstain,
    totalAgree,
    totalDisagree,
    totalAbstain,
    totalVotes,
    result,
  }
`;