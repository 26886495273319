import gql from "graphql-tag";
import { accountFragment } from "./Account";
export const parentAccountFragment = gql`
  fragment ParentAccount on Account {
    ...Account,
    installments {
      ...Account,
    } 
  }
  ${accountFragment}
`;