import gql from "graphql-tag";
import { movementResultCenterFragment } from "./MovementResultCenter";
export const movementEntryFragment = gql`
  fragment MovementEntrySimple on Movement {
    id,
    transactionId,      
    shortComplement,
    longComplement,
    referencedDocument,
    kind,        
    reversingEntry,
    reversed,
    value,
    date, 
    setup,
    resultCenters {
      ...MovementResultCenter
    }
  }
  ${movementResultCenterFragment}
`;