export const SINGULAR = 'singular';
export const FEDERATION = 'federation';
export const CONFEDERATION = 'confederation';
export const NATURAL = 'natural';
export const JURIDICAL = 'juridical';
export const BOTH = 'both';
export const ORGANIZATION_LEVELS = [{
  value: SINGULAR,
  label: "Singular"
}, {
  value: FEDERATION,
  label: "Federação"
}, {
  value: CONFEDERATION,
  label: "Confederação"
}];
export const STATUTORY_PERSON_ACCEPTANCE_OPTIONS = [{
  value: NATURAL,
  label: "Natural"
}, {
  value: JURIDICAL,
  label: "Jurídica"
}, {
  value: BOTH,
  label: "Ambos"
}];