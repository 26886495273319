import gql from "graphql-tag";
import { pollFragment } from "./Poll";
export const phaseFragment = gql`
  fragment Phase on Phase  { 
    id,
    title,
    duration,
    position,
    description,
    currentEvent,
    start,
    canOpen,
    canClose,
    canPause,
    canReopen,
    canSkip,
    documents64 {
      id,
      data,
      filename,
      contentType,
      key
    },
    polls {
      ...Poll
    }
  }
${pollFragment}
`;