import gql from "graphql-tag";
import { electionTotalFragment } from "../fragments/ElectionTotal";
import { slateFragment } from "../fragments/Slate";
export const GQL_COOPERATIVE_DASHBOARD_MEMBERS_COUNT = gql`
  query GetMemberCount($membersCount: Boolean) {    
    cooperativeDashboard(membersCount: $membersCount) { 
        membersCount
    }
  }
`;
export const GQL_COOPERATIVE_DASHBOARD_ASSEMBLY_INFO = gql`
query GetAssemblyInfo($assembly: Boolean) {    
  cooperativeDashboard(assembly: $assembly) { 
    assemblyInfo { 
      id,
      name,
      kind,
      start,
      finish,
      participantsCount,
      phases {
        id,
        title,
        polls {
          id,
          question,
          description,
          totalVote {
            id,
            totalAgree,
            totalDisagree,
            totalAbstain,
            totalVotes,
            result,
          }
        }
      }
    }
  }
}
`;
export const GQL_COOPERATIVE_DASHBOARD_ELECTION_INFO = gql`
query GetElectionInfo($election: Boolean) {    
  cooperativeDashboard(election: $election) { 
    electionInfo { 
      id,
      start,
      finish,
      councilType,
      electionTotal {
        ...ElectionTotal
      }
      slates {
        ...Slate
      }
    }
  }
}
${electionTotalFragment}
${slateFragment}
`;