import { MAIN } from "../constants/addresses/AddressesFieldsOptions";
import { packObjectList, pluck, getSessionInfo } from 'grifo-components';
import { NORMAL_USER, REGULAR } from "../constants/users/UsersFieldsOptions";
export const userParamBuilder = (record, values) => {
  values.phone.naming = MAIN;
  return {
    attributes: {
      id: record ? record.id : null,
      email: values.email,
      profile: values.profile,
      tenantId: values.tenantId,
      companyId: values.companyId,
      person: {
        name: values.name,
        document: values.document,
        photo64: values.photo64,
        phone: values.phone,
        gender: values.gender
      },
      tenantUsers: values.tenantUsers.map(tenantUser => {
        tenantUser.roleId = tenantUser.roleId;
        tenantUser.authorizations = tenantUser.authorizations;
        tenantUser.companyUsers = packObjectList(tenantUser.companyUsers, "companyId");
        if (tenantUser.tenantId === values.tenantId) tenantUser.companyId = values.companyId;else tenantUser.companyId = ((tenantUser.companyUsers || [])[0] || {}).companyId;
        return tenantUser;
      })
    }
  };
};
export const userDefaultBuilder = record => {
  let object = record || {};
  let person = object.person || {};
  let session = getSessionInfo();
  let ownTenantId = session.tenantId;
  let ownCompanyId = (session.company || {}).id;
  return {
    id: object.id,
    email: object.email,
    profile: object.profile || REGULAR,
    tenantId: object.tenantId || ownTenantId,
    companyId: (object.company || {}).id || ownCompanyId,
    name: object.name || person.name,
    gender: person.gender,
    document: object.document || person.document,
    photo64: person.photo64,
    phone: person.phone,
    address: person.address,
    tenantUsers: (object.tenantUsers || [{
      tenantId: ownTenantId,
      profile: NORMAL_USER,
      companyUsers: [{
        companyId: ownCompanyId
      }]
    }]).map(tenantUser => {
      return {
        ...tenantUser,
        ...{
          companyUsers: pluck(tenantUser.companyUsers, "companyId")
        }
      };
    })
  };
};