import gql from "graphql-tag";
import { documentFolderKindSimpleFragment } from "./DocumentFolderKindSimple";
export const documentFolderFragment = gql`
  fragment DocumentFolder on DocumentFolder {
    id,
    name,
    documentFolderKinds {
     ...DocumentFolderKindSimple
    }
  }
  ${documentFolderKindSimpleFragment}
`;