import { PROTEST_MESSAGES } from "../constants/bankSlips/BankSlipFieldsOptions";
import { DAILY, DO_NOT_PROTEST, TO_RECEIVE } from "../constants/operations/OperationsFieldsOptions";
import { percentageFormat, getLabelByValue } from 'grifo-components';
import moment from 'moment';
export const buildInstructions = (operation, form) => {
  if (operation.kind != TO_RECEIVE) return;
  let latePaymentInterestKind = operation.latePaymentInterestKind;
  let latePaymentFine = operation.latePaymentFine;
  if (latePaymentInterestKind == DAILY) {
    latePaymentFine = operation.latePaymentFine / 30;
  }
  let latePaymentInterest = operation.latePaymentInterest;
  let protestCode = operation.protestCode;
  let protestMessage = getLabelByValue(PROTEST_MESSAGES, operation.protestCode);
  let dueDate = form.getFieldsValue().competencyDate;
  let lateDate = moment(operation.daysAfterOverdueToExpiredTitle + dueDate).format("DD/MM/YYYY");
  let instructions = [];
  if (latePaymentFine == 0 && latePaymentInterest == 0) {
    instructions.push('Não cobrar encargos por atraso');
  } else {
    if (latePaymentInterest > 0) {
      instructions.push(`Após ${lateDate} juros de ${percentageFormat(latePaymentInterest)}/dia`);
    }
    if (latePaymentFine > 0) {
      instructions.push(`Após ${lateDate} multa de ${percentageFormat(latePaymentFine)}`);
    }
    instructions.push('Não conceder desconto');
  }
  if (protestCode != DO_NOT_PROTEST) {
    if (operation.daysToProtest == 0) {
      instructions.push(`Protesto no vencimento`);
    } else {
      instructions.push(`Protesto no ${operation.daysToProtest}${protestMessage}`);
    }
  }
  form.setFieldsValue({
    instruction1: '',
    instruction2: '',
    instruction3: '',
    instruction4: '',
    instruction5: ''
  });
  for (let index = 0; index < instructions.length; index++) {
    const instruction = instructions[index];
    let name = `instruction${index + 1}`;
    form.setFieldsValue(Object.assign({}, form.getFieldsValue(), {
      [name]: instruction
    }));
  }
};