import moment from 'moment';
export const sanitize = (params, omitFields) => {
  const omitProps = (key, value) => omitFields.indexOf(key) === -1 ? value : undefined;
  return JSON.parse(JSON.stringify(params, omitProps));
};
export const cloneObject = (object, excludeKeys = []) => {
  if (null === object || "object" !== typeof object) return object;
  let copy = object.constructor();
  for (let attr in object) {
    if (object.hasOwnProperty(attr)) {
      if (Array.isArray(object[attr])) {
        if (excludeKeys.includes(attr.toString()) || excludeKeys.includes('*')) {
          copy[attr] = [];
          for (let child in object[attr]) {
            let item = object[attr][child];
            delete item?.id;
            copy[attr].push(item);
          }
        } else copy[attr] = object[attr];
      } else {
        copy[attr] = moment.isMoment(object[attr]) ? object[attr] : "object" === typeof object[attr] ? cloneObject(object[attr], [...excludeKeys]) : object[attr];
        if (excludeKeys.includes(attr.toString()) || excludeKeys.includes('*')) delete copy[attr]?.id;
      }
    }
  }
  delete copy?.id;
  return copy;
};