import gql from "graphql-tag";
export const resultCenterFragment = gql`
  fragment ResultCenter on ResultCenter {
    id,
    description,
    name,
    discardedAt,
    parent {
      id
    }
  }
`;