export const FINANCIAL = 'financial';
export const INVOICE = 'from_invoice';
export const WAYBILL = 'waybill';
export const DISTRIBUTION = 'distribution';
export const FREIGHT = 'freight';
export const ACCOUNT_ORIGINS = [{
  value: FINANCIAL,
  label: 'Financeiro'
}, {
  value: INVOICE,
  label: 'Pedido'
}, {
  value: WAYBILL,
  label: 'Conhecimento de transporte'
}, {
  value: DISTRIBUTION,
  label: 'Distribuição de resultado da cooperativa'
}, {
  value: FREIGHT,
  label: 'Frete'
}];