import gql from "graphql-tag";
export const contactFragment = gql`
  fragment Contact on Contact {
    id,
    contactId,
    contact {
      id,
      name,
      document
    }    
  }
`;