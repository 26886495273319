import { sanitize, today } from 'grifo-components';
export const transferParamBuilder = (record, values) => {
  let documents64 = values.documents64 ? sanitize(values.documents64, ['name', 'url']) : null;
  return {
    movement: {
      accountId: record.account.id,
      operationId: values.operationId,
      value: values.value,
      date: values.date.format("YYYY-MM-DD"),
      shortComplement: values.shortComplement,
      longComplement: values.longComplement,
      referencedDocument: values.referencedDocument,
      documents64: documents64
    },
    sourceCurrentAssetId: (record.currentAsset || record).id,
    targetCurrentAssetId: values.currentAssetId
  };
};
export const transferDefaultBuilder = record => {
  return {
    accountId: record.id,
    value: 0.0,
    additions: 0.0,
    discounts: 0.0,
    finalBalance: 0.0,
    date: today(),
    shortComplement: "Transferência entre disponibilidades"
  };
};