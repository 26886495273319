import gql from "graphql-tag";
import { slateFragment } from "../fragments/Slate";
export const GQL_SLATES_LIST = gql`
  query GetAll($pageSize: Int, $cursor: String) {    
    slatesPaginated(first: $pageSize, after: $cursor) {
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasPreviousPage
        hasNextPage
      } 
      edges { 
        cursor
        node {
          ...Slate
        }
      }
    }    
  }
  ${slateFragment}
`;
export const GQL_SLATE_BY_ID = gql`
query GetById($id: ID) {    
  slates(id: $id) { 
      ...Slate
  }
}
${slateFragment}
`;
export const GQL_SLATES_SIMPLE_LIST = gql`
  {
    slates { 
      ...Slate
    }
  }
  ${slateFragment}
`;
export const GQL_SLATES_BY_COUNCIL_LIST = gql`
  query GetByCouncilType($councilType: String) {    
    slates(councilType: $councilType) {
      ...Slate
    }
  }
  ${slateFragment}
`;
export const GQL_SLATE_INACTIVATE = gql`
  mutation Inactivate($id: ID!) { 
    inactivate(input: {id: $id, className: "Cooperative::Slate"}) {
      id,
      discardedAt
    }
  }
`;
export const GQL_SLATE_STORE = gql`
  mutation StoreSlate($attributes: SlateAttributes!) {
    storeSlate(input: {attributes: $attributes}) { 
        slate {
        ...Slate
    }, 
      errors 
    } 
  }
  ${slateFragment}
`;