import { SPECIFIC } from '../constants/holidays/HolidaysFieldsOptions';
import { sanitizeBackend } from 'grifo-components';
export const holidayParamBuilder = (record, values) => {
  return {
    attributes: {
      id: record ? record.id : null,
      name: values.name,
      date: values.date,
      day: values.day,
      month: values.month,
      kind: values.kind,
      recurrence: values.recurrence,
      facultativePoint: values.facultativePoint
    }
  };
};
export const holidayDefaultBuilder = record => {
  const date = (record || {}).date;
  const yearlyDate = (record || {}).yearlyDate;
  return {
    name: record?.name,
    yearlyDate: sanitizeBackend(yearlyDate),
    date: sanitizeBackend(date) || sanitizeBackend(yearlyDate),
    day: (record || {}).day,
    month: (record || {}).month,
    kind: (record || {}).kind,
    recurrence: (record || {}).recurrence || SPECIFIC,
    facultativePoint: (record || {}).facultativePoint || false
  };
};