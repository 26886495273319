import gql from "graphql-tag";
import { cooperativePersonFragment } from "../fragments/CooperativePerson";
import { addressFragment, phoneFragment } from 'admin-front';
export const cooperativeCompanyFragment = gql`
  fragment CooperativeCompany on CooperativeCompany {
    id,
    kind,
    name,
    isCooperative,
    person {
      ...CooperativePerson
    },
    address {
      ...Address
    },
    phone {
      ...Phone
    },
  }
  ${cooperativePersonFragment}
  ${addressFragment}
  ${phoneFragment}  
`;