export const MUNICIPAL = 'municipal';
export const STATE = 'state';
export const NATIONAL = 'national';
export const SPECIFIC = 'specific';
export const YEARLY = 'yearly';
export const HOLIDAYS_KIND_OPTIONS = [{
  value: MUNICIPAL,
  label: 'Municipal'
}, {
  value: STATE,
  label: 'Estadual'
}, {
  value: NATIONAL,
  label: 'Nacional'
}];
export const HOLIDAYS_RECURRENCE_OPTIONS = [{
  value: SPECIFIC,
  label: 'Específico'
}, {
  value: YEARLY,
  label: 'Anual'
}];