export const IN_CASH = 'in_cash';
export const INSTALLMENT = 'installment';
export const INSTALLMENT_WITH_INGRESS = 'installment_with_ingress';
export const DAY = 'day';
export const WEEK = 'week';
export const MONTH = 'month';
export const YEAR = 'year';
export const CONDITIONS_OPTIONS_BY_KEY = [{
  value: '0',
  label: 'À Vista'
}, {
  value: '1',
  label: 'Parcelado'
}, {
  value: '2',
  label: 'Parcelado com entrada'
}];
export const CONDITIONS_OPTIONS = [{
  value: IN_CASH,
  label: 'À Vista'
}, {
  value: INSTALLMENT,
  label: 'Parcelado'
}, {
  value: INSTALLMENT_WITH_INGRESS,
  label: 'Parcelado com entrada'
}];
export const TIME_KIND_OPTIONS = [{
  value: DAY,
  label: 'Dia',
  plural: "dia(s)"
}, {
  value: WEEK,
  label: 'Semana',
  plural: "semana(s)"
}, {
  value: MONTH,
  label: 'Mês',
  plural: "mes(es)"
}, {
  value: YEAR,
  label: 'Ano',
  plural: "ano(s)"
}];