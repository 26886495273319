import { dateOrToday, sanitize, getSessionInfo } from 'grifo-components';
import { GENERATE_ALL_BANK_SLIPS } from '../constants/account/AccountFieldsOptions';
import { accountPaymentCompleteBuilder } from "./AccountPaymentBuilder";
export const accountParamBuilder = (record, values) => {
  values.resultCenters = values.resultCenters ? values.resultCenters.filter(rc => rc.apportionment > 0) : undefined;
  values.resultCenters = values.resultCenters ? sanitize(values.resultCenters, ['resultCenter', 'value', 'movementId']) : undefined;
  let bankSlip = values.bankAccountId ? {
    bankAccountId: values.bankAccountId,
    ourNumber: values.ourNumber,
    option: values.option,
    agreementId: values.agreementId,
    instruction1: values.instruction1,
    instruction2: values.instruction2,
    instruction3: values.instruction3,
    instruction4: values.instruction4,
    instruction5: values.instruction5
  } : {};
  return {
    attributes: {
      id: record ? record.id : null,
      operationId: values.operationId,
      personId: values.personId,
      value: values.value,
      amount: values.amount,
      date: values.competencyDate.format("YYYY-MM-DD"),
      shortComplement: values.shortComplement,
      longComplement: values.longComplement,
      referencedDocument: values.referencedDocument,
      resultCenters: values.resultCenters ? values.resultCenters.map(object => ({
        ...object,
        companyId: getSessionInfo().company.id
      })) : undefined,
      documents64: values.documents64 ? sanitize(values.documents64, ['name', 'url']) : null,
      companyId: getSessionInfo().company.id,
      bankSlip: bankSlip,
      memberRegistrationId: values.memberRegistrationId,
      payments: values.payments.map(object => {
        delete object.paymentMethod;
        delete object.paymentCondition;
        delete object.date;
        delete object.methodKind;
        delete object.condition;
        delete object.bankSlip;
        return {
          ...object
        };
      })
    }
  };
};
export const accountDefaultBuilder = record => {
  const object = record || {};
  const entry = record?.entry;
  return {
    id: object.id,
    operationId: object.operationId,
    personId: (object.person || {}).id,
    person: object.person,
    value: object.value || 0.0,
    amount: object.value || 0.0,
    competencyDate: dateOrToday(object.date),
    dueDate: dateOrToday(object.dueDate),
    shortComplement: entry?.shortComplement,
    longComplement: entry?.longComplement,
    referencedDocument: entry?.referencedDocument,
    resultCenters: entry?.resultCenters,
    payments: accountPaymentCompleteBuilder(object),
    origin: object.origin,
    rescheduledAccount: object.rescheduledAccount,
    reschedulingAccount: object.reschedulingAccount,
    option: GENERATE_ALL_BANK_SLIPS,
    canEdit: object.canEdit,
    documents64: (entry?.documents64 || []).map(file => ({
      name: file.filename,
      status: 'done',
      uid: file.id,
      key: file.key,
      url: file.key,
      filename: file.filename,
      contentType: file.contentType,
      data: file.data
    }))
  };
};