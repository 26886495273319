import { DISABLED, NEW_RESOURCE, STAFF, SUPER_ADMIN, TENANT_ADMIN, UNAVAILABLE, UNDER_CONSTRUCTION } from "../constants/Resource";
export const isSkipAuthorization = feature => {
  return feature.skipAuthorization;
};
export const isNewFeature = feature => {
  return feature.status === NEW_RESOURCE;
};
export const isUnderConstructionFeature = feature => {
  return feature.status === UNDER_CONSTRUCTION;
};
export const isDisabledFeature = feature => {
  return feature.status === DISABLED;
};
export const isUnavailableFeature = feature => {
  return feature.status === UNAVAILABLE;
};
export const isOnlyForSuperAdmin = feature => {
  return feature.authorization === SUPER_ADMIN;
};
export const isOnlyForStaff = feature => {
  return feature.authorization === STAFF;
};
export const isOnlyForTenantAdmin = feature => {
  return feature.authorization === TENANT_ADMIN;
};