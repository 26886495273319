export const pollEventParamBuilder = (record, values) => {
  return {
    id: record,
    minutes: values.minutes
  };
};
export const pollEventDefaultBuilder = record => {
  return {
    title: (record || {}).title,
    description: (record || {}).description,
    duration: (record || {}).duration
  };
};