import { getSessionInfo } from 'grifo-components';
export const votingParamBuilder = (record, values) => {
  return {
    attributes: {
      electionId: record ? record.id : null,
      choice: values.slate === "0" ? 'abstained' : 'slate',
      slateId: values.slate === "0" ? undefined : values.slate,
      personId: getSessionInfo().person.id
    }
  };
};
export const votingDefaultBuilder = record => {
  return {
    slates: (record || {}).slates
  };
};