import gql from "graphql-tag";
import { organizationSimpleFragment } from "./OrganizationSimple";
export const organizationFragment = gql`
  fragment Organization on Organization {
    ...OrganizationSimple
    parent {
      ...OrganizationSimple
    }
  }
  ${organizationSimpleFragment}
`;