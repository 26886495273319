import gql from "graphql-tag";
import { incomeFragment } from "../fragments/Income";
export const GQL_INCOMES_SEARCH = gql`
  {
    incomes {
      ...Income,
    }    
  }
  ${incomeFragment}  
`;
export const GQL_INCOMES_LIST = gql`
  query GetAll($pageSize: Int, $cursor: String, $search: String) {    
    incomesPaginated(first: $pageSize, after: $cursor, search: $search) {
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasPreviousPage
        hasNextPage
      } 
      edges { 
        cursor
        node {
          ...Income
        }
      }
    }    
  }
  ${incomeFragment}
`;
export const GQL_INCOME_INACTIVATE = gql`
  mutation Inactivate($id: ID!) {
    inactivate(input: {id: $id, className: "Income"}) {
      id,
      discardedAt 
    }
  }
`;
export const GQL_INCOME_STORE = gql`
  mutation StoreIncome($attributes: IncomeAttributes!) {
    storeIncome(input: {attributes: $attributes}) { 
      income { 
        ...Income,
      }, 
      errors 
    } 
  }
  ${incomeFragment}  
`;