export const CHIEF_COUNSELOR = 'chief_counselor';
export const COUNSELOR = 'counselor';
export const SURROGATE = 'surrogate';
export const ROLE_KIND = [{
  value: CHIEF_COUNSELOR,
  label: 'Conselheiro Chefe'
}, {
  value: COUNSELOR,
  label: 'Conselheiro'
}, {
  value: SURROGATE,
  label: 'Suplentes'
}];