import gql from "graphql-tag";
import { registrationAgencyFragment } from "../fragments/RegistrationAgency";
export const GQL_REGISTRATION_AGENCIES_SEARCH = gql`
  {
    registrationAgencies {
      ...RegistrationAgency,
    }    
  }
  ${registrationAgencyFragment}
`;
export const GQL_REGISTRATION_AGENCIES_LIST = gql`
  query GetAll($pageSize: Int, $cursor: String, $registrationAgencyFilter: RegistrationAgencyFilter) {    
    registrationAgenciesPaginated(first: $pageSize, after: $cursor, registrationAgencyFilter: $registrationAgencyFilter) {
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasPreviousPage
        hasNextPage
      } 
      edges { 
        cursor
        node {
          ...RegistrationAgency
        }
      }
    }    
  }
  ${registrationAgencyFragment}
`;
export const GQL_REGISTRATION_AGENCY_STORE = gql`
  mutation StoreRegistrationAgency($attributes: RegistrationAgencyAttributes!) {
    storeRegistrationAgency(input: {attributes: $attributes}) { 
      registrationAgency { 
        ...RegistrationAgency,
      }, 
      errors 
    } 
  }
  ${registrationAgencyFragment}  
`;
export const GQL_REGISTRATION_AGENCY_INACTIVATE = gql`
mutation Inactivate($id: ID!) {
  inactivate(input: {id: $id, className: "RegistrationAgency"}) {
    id,
    discardedAt 
  }
}
`;