export const MAIN = 'main';
export const CHARGE = 'charge';
export const WORK = 'work';
export const ADDRESS_NAMING_OPTIONS = [{
  value: MAIN,
  label: 'Principal'
}, {
  value: CHARGE,
  label: 'Cobrança'
}, {
  value: WORK,
  label: 'Trabalho'
}];