import gql from "graphql-tag";
import { pollFragment } from "../fragments/Poll";
export const GQL_POLLS_LIST = gql`
  query GetAll($pageSize: Int, $cursor: String) {    
    pollsPaginated(first: $pageSize, after: $cursor) {
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasPreviousPage
        hasNextPage
      } 
      edges { 
        cursor
        node {
          ...Poll
        }
      }
    }    
  }
  ${pollFragment}
`;
export const GQL_POLL_STORE = gql`
  mutation StorePoll($attributes: PollAttributes!) {
    storePoll(input: {attributes: $attributes}) { 
      poll {
      ...Poll
    },
      errors
    }
  }
  ${pollFragment}
`;
export const GQL_POLL_INACTIVATE = gql`
  mutation Inactivate($id: ID!) {
    inactivate(input: {id: $id, className: "Cooperative::Poll"}) {
      id,
      discardedAt
    }
  }
`;