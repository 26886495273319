import gql from "graphql-tag";
import { resultCenterFragment } from "./ResultCenter";
export const parentResultCenterFragment = gql`
  fragment ParentResultCenter on ResultCenter {
    ...ResultCenter,
    parent {
      ...ResultCenter,
    },
    children {
      ...ResultCenter,
      children {
        ...ResultCenter,
        children {
          ...ResultCenter,
          children {
            ...ResultCenter,
            children {
              ...ResultCenter,
            }
          }
        }
      }
    }    
  }
  ${resultCenterFragment}
`;