import gql from "graphql-tag";
export const resourceFragment = gql`
  fragment Resource on Resource {
    id,
    kind,
    status,    
    kind,
    key,
    name,
    childrenCount    
  }
`;
export const resourceListFragment = gql`
  fragment ResourceList on Resource {
    ...Resource
     children {
       ...Resource
       children {
         ...Resource,
         children {
           ...Resource,
           children {
             ...Resource,
             children {
               ...Resource,
             }
           }
         }
       }
     }
  }
  ${resourceFragment} 
`;