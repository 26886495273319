import gql from "graphql-tag";
import { personSearchFragment } from "./PersonSearch";
import { companySimpleFragment } from "./CompanySimple";
import { userSimpleFragment, userStoreSimpleFragment } from "./UserSimple";
import { tenantUserFragment } from "./TenantUser";
import { tenantFragment } from "./Tenant";
import { authorizationFragment } from "./Authorization";
export const userFragment = gql`
  fragment User on User {
    ...UserSimple
    person {
      ...PersonSearch
    },
    tenant {
      ...Tenant
    },
    tenants {
      ...Tenant
    },
    tenantUser {
      ...TenantUser
    }, 
    tenantUsers {
      ...TenantUser
    },   
    companies {
      ...CompanySimple
    },
    company {
      ...CompanySimple
    }
    authorizations {
      ...Authorization
    }
  }
  ${userSimpleFragment}
  ${tenantFragment}  
  ${personSearchFragment}
  ${tenantUserFragment}    
  ${authorizationFragment}  
  ${companySimpleFragment}
`;
export const userStoreFragment = gql`
  fragment UserStore on UserStore {
    ...UserStoreSimple
    person {
      ...PersonSearch
    },
    tenant {
      ...Tenant
    },
    tenants {
      ...Tenant
    },
    company {
      ...CompanySimple
    },
    tenantUser {
      ...TenantUser
    }, 
    currentTenantUser {
      person {
        ...PersonSearch
      }
    }, 
    tenantUsers {
      ...TenantUser
    },
    companies {
      ...CompanySimple
    },
    company {
      ...CompanySimple
    }
  }
  ${userStoreSimpleFragment}
  ${personSearchFragment}
  ${tenantFragment}    
  ${tenantUserFragment}    
  ${companySimpleFragment}  
`;