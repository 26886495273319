export const authorizationParamBuilder = (record, values) => {
  let authorizations = [];
  Object.entries(values.authorizations).map(entry => {
    authorizations.push({
      id: entry[1].id || null,
      resourceId: entry[1].resourceId,
      kind: entry[1].kind
    });
  });
  return authorizations;
};
export const authorizationDefaultBuilder = record => {
  return {
    authorizations: record?.authorizations
  };
};