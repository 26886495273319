import { sanitizeBackend, dateOrToday, sanitize } from 'grifo-components';
export const assemblyParamBuilder = (record, values) => {
  let participants = values.participants ? sanitize(values.participants, ['person']) : null;
  participants = (participants || []).map(object => ({
    ...object,
    voteOfMinerva: object.voteOfMinerva === undefined ? false : object.voteOfMinerva,
    canVote: object.canVote === undefined ? false : object.canVote
  }));
  let documents64 = values.documents64 ? sanitize(values.documents64, ['name', 'url']) : null;
  return {
    attributes: {
      id: record ? record.id : null,
      name: values.name,
      kind: values.kind,
      link: values.link,
      description: values.description,
      start: values.start,
      finish: values.finish,
      participants: participants,
      documents64: documents64,
      phases: (values.phases || []).map((object, index) => {
        delete object.canOpen;
        delete object.canReopen;
        delete object.canClose;
        delete object.canPause;
        delete object.canSkip;
        delete object.currentEvent;
        delete object.start;
        return {
          ...object,
          position: index + 1,
          duration: parseInt(object.duration),
          documents64: ((object || {}).documents64 || []).map(doc => {
            delete doc.name;
            delete doc.url;
            return {
              ...doc
            };
          }),
          polls: ((object || {}).polls || []).map((poll, index) => {
            delete poll.currentAnswer;
            delete poll.hasVoted;
            delete poll.isPresident;
            delete poll.totalVote;
            delete poll.discardedAt;
            return {
              ...poll,
              position: index + 1,
              documents64: ((poll || {}).documents64 || []).map(doc => {
                delete doc.name;
                delete doc.url;
                return {
                  ...doc
                };
              })
            };
          })
        };
      })
    }
  };
};
export const assemblyDefaultBuilder = record => {
  ((record || []).phases || []).forEach(element => {
    element.polls.forEach(poll => {
      poll.documents64 = (poll.documents64 || []).map(file => ({
        name: file.filename,
        status: 'done',
        uid: file.id,
        key: file.key,
        url: file.key,
        filename: file.filename,
        contentType: file.contentType,
        data: file.data
      }));
    });
    element.documents64 = (element.documents64 || []).map(file => ({
      name: file.filename,
      status: 'done',
      uid: file.id,
      key: file.key,
      url: file.key,
      filename: file.filename,
      contentType: file.contentType,
      data: file.data
    }));
  });
  const start = (record || {}).start;
  const finish = (record || {}).finish;
  return {
    name: (record || {}).name,
    kind: (record || {}).kind,
    link: (record || {}).link,
    description: (record || {}).description,
    participants: (record || {}).participants,
    start: dateOrToday(start),
    finish: sanitizeBackend(finish),
    phases: (record || {}).phases,
    canOpen: (record || {}).canOpen,
    canClose: (record || {}).canClose,
    closed: (record || {}).closed,
    isPresident: (record || {}).isPresident,
    datetime: ((record || {}).currentEvent || {}).datetime,
    eventMinutes: (record || {}).eventMinutes,
    documents64: ((record || {}).documents64 || []).map(file => ({
      name: file.filename,
      status: 'done',
      uid: file.id,
      key: file.key,
      url: file.key,
      filename: file.filename,
      contentType: file.contentType,
      data: file.data
    }))
  };
};