import gql from "graphql-tag";
export const personDocumentFragment = gql`
  fragment PersonDocument on PersonDocument {
    id,
    personId,
    issueDate,
    personId,
    documentTypeId,
    documents64 {
      id,
      data,
      filename,
      contentType,
      key
    }
  }
`;