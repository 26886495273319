import gql from "graphql-tag";
import { reconciliationFragment } from "../fragments/Reconciliation";
export const GQL_RECONCILIATION_STORE = gql`
  mutation StoreReconciliation($attributes: ReconciliationAttributes!) {
    storeReconciliation(input: {attributes: $attributes}) { 
      reconciliation {
        ...Reconciliation
      },
      errors 
    } 
  }
  ${reconciliationFragment}
`;
export const GQL_STORE_RECONCILIATION_MOVEMENT = gql`
  mutation StoreReconciliationMovement($attributes: ReconciliationMovementAttributes!){
    storeReconciliationMovement(input: {attributes: $attributes}){
      id,
      errors
    }
  }
`;
export const GQL_REMOVE_ACCOUNT_FROM_RECONCILIATION_TRANSACTION = gql`
  mutation RemoveAccountFromReconciliationTransaction($reconciliationMovementId: ID, $transactionId: ID, $accountId: ID, $movementId: ID){
    removeAccountFromReconciliationTransaction(input: {reconciliationMovementId: $reconciliationMovementId, transactionId: $transactionId, accountId: $accountId, movementId: $movementId}){
      id,
      errors
    }
  }
`;
export const GQL_RECONCILIATIONS_LIST = gql`
  query Get($accountId: ID) {    
    reconciliation(accountId: $accountId) {
      ...Reconciliation
    }
  }
  ${reconciliationFragment}
`;