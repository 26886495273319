import gql from "graphql-tag";
import { currentAssetOpeningFragment } from "../fragments/CurrentAssetOpening";
export const GQL_CURRENT_ASSET_OPENING_STORE = gql`
  mutation StoreCurrentAssetOpening($attributes: CurrentAssetOpeningAttributes!) {
    storeCurrentAssetOpening(input: {attributes: $attributes}) { 
      currentAssetOpening {
        ...CurrentAssetOpening
    }, 
      errors 
    } 
  }
  ${currentAssetOpeningFragment}
`;