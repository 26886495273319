export const WHITE = 'white';
export const WHITESMOKE = '#F0F0F0';
export const RED = 'red';
export const LIGHT_RED = '#ff4d4f';
export const NEGATIVE_RED = '#ec0000';
export const BLUE = 'blue';
export const GEEKBLUE = '#1890ff';
export const LIGHT_BLUE = '#40a9ff';
export const CYAN = 'cyan';
export const VOLCANO = '#fa541c';
export const ORANGE = 'orange';
export const GOLD = 'gold';
export const GREEN = 'green';
export const LIGHT_GREEN = '#F0FFEB';
export const LIME = '#d3f261';
export const MAGENTA = '#ff85c0';
export const PURPLE = 'purple';
export const PINK = 'pink';
export const DARK_PINK = '#8884d8';
export const GREY = 'grey-3';
export const DISABLE_COLOR = '#d9d9d9';
export const GREEN_BAR = '#82ca9d';
export const PURPLE_BAR = '#8884d8';
export const RED_BAR = '#CD0000';
export const LIGHT_YELLOW = '#fdf8e1';
export const DARK_YELLOW = '#F0A500';
export const ANDROID_GREEN = '#AACC00';
export const NEW_GREEN = '#52c41a';
export const POSITIVE_GREEN = '#00da3c';
export const MONEY_DARK = '#FFD447';
export const MONEY_LIGHT = '#FFFAEB';
export const COLORS = [RED, LIGHT_RED, BLUE, GEEKBLUE, LIGHT_BLUE, CYAN, VOLCANO, ORANGE, GOLD, GREEN, LIME, MAGENTA, PURPLE, PINK, GREY];
export const COLORS_FOR_GRAPHS = [DARK_PINK, '#83a6ed', '#8dd1e1', LIGHT_GREEN, '#a4de6c', '#d0ed57', '#ffd675', '#ff9c6e', '#ff7875', LIGHT_RED];
export const GREEN_PALETTE = ['#95de64', '#73d13d', '#52c41a', '#389e0d', '#237804', '#135200'];
export const RED_PALETTE = ['#fce7e6', '#ffa39e', '#ff7875', '#ff4d4f', '#f5222d', '#cf1322', '#a8071a'];
export const BLUE_PALETTE = ['#e6f7ff', '#91d5ff', LIGHT_BLUE, GEEKBLUE, '#406bf7', '#096dd9', '#1d39c4'];
export const VOLCANO_PALETTE = ['#ffbb96', '#ff9c6e', '#ff7a45', '#d4380d', '#ad2102', '#8A0000'];
export const GEEK_BLUE_PALETTE = ['#adc6ff', '#85a5ff', '#597ef7', '#2f54eb', '#1d39c4', '#10239e'];
export const THERMOMETER_COLORS = ['#237804', '#66c217', '#84b710', '#ada001', '#c68c00', '#db7400', '#ee5400', '#f44600', '#d4380d', '#a8071a'];