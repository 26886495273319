import gql from "graphql-tag";
export const GQL_CITIES_LIST = gql`
  query GetByName($name: String) {    
    cities(name: $name) {
      id,
      cityState,
    }
  }
`;
export const GQL_CITY_ID_SEARCH = gql`
  query Get($id: ID) {    
    cities(id: $id) {
      id,
      cityState,
    }
  }
`;