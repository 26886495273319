import gql from "graphql-tag";
import { addressFragment } from "./Address";
import { phoneFragment } from "./Phone";
import { profileFragment } from "./Profile";
export const personSearchFragment = gql`
  fragment PersonSearch on Person {
    id,
    name,
    document,
    tradeName,
    gender,
    birth,
    closure,    
    rntrcCode,
    email,
    discardedAt,
    phone {
      ...Phone
    }
    addresses {
      ...Address
    }
    profile {
      ...Profile
    }
  }
  ${phoneFragment}
  ${addressFragment}
  ${profileFragment}
`;