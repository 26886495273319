import gql from "graphql-tag";
export const personSimpleFragment = gql`
  fragment PersonSimple on Person {
    id,
    name,
    tradeName,
    document,
    email,
    governmentInstitution,
    discardedAt,
  }
`;