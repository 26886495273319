import gql from "graphql-tag";
import { operationSelectFragment, operationListFragment, operationLoadFragment, operationLoadResultCentersFragment } from "../fragments/Operation";
export const GQL_OPERATIONS_SELECT = gql`
  query GetByKind($kind: String) {    
    operations(kind: $kind) {
      ...OperationSelect
    }
  }
  ${operationSelectFragment}
`;
export const GQL_OPERATIONS_LIST = gql`
  query GetAll($pageSize: Int, $cursor: String, $search: String) {    
    operationsPaginated(first: $pageSize, after: $cursor, search: $search) {
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasPreviousPage
        hasNextPage
      } 
      edges { 
        cursor
        node {
          ...OperationList
        }
      }
    }    
  }
  ${operationListFragment}
`;
export const GQL_OPERATION_LOAD = gql`
  query GetById($id: ID) {    
    operations(id: $id) {
      ...OperationLoad
    }
  }
  ${operationLoadFragment}
`;
export const GQL_OPERATION_LOAD_RESULT_CENTERS = gql`
  query GetById($id: ID) {    
    operations(id: $id) {
      ...OperationLoadResultCenters
    }
  }
  ${operationLoadResultCentersFragment}
`;
export const GQL_OPERATION_STORE = gql`
  mutation StoreOperation($attributes: OperationAttributes!) {
    storeOperation(input: {attributes: $attributes}) { 
      operation { 
        ...OperationList
      }, 
      errors 
    } 
  }
  ${operationListFragment}
`;
export const GQL_OPERATION_INACTIVATE = gql`
  mutation Inactivate($id: ID!) {
    inactivate(input: {id: $id, className: "Financial::Operation"}) {
      id,
      discardedAt
    }
  }
`;