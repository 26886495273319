import gql from "graphql-tag";
import { parentResultCenterFragment } from "../fragments/ResultCenterParent";
export const GQL_SEARCH_RESULT_CENTERS = gql`
  query GetByName($name: String) {    
    resultCenters(name: $name) { 
      ...ParentResultCenter
    }
  }
  ${parentResultCenterFragment}   
`;
export const GQL_RESULT_CENTERS_LIST = gql`
  query GetAll($pageSize: Int, $cursor: String, $resultCenterFilter: ResultCenterFilter) {    
    resultCentersPaginated(first: $pageSize, after: $cursor, resultCenterFilter: $resultCenterFilter) {
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasPreviousPage
        hasNextPage
      } 
      edges { 
        cursor
        node {
          ...ParentResultCenter
        }
      }
    }    
  }
  ${parentResultCenterFragment}
`;
export const GQL_ALL_AVAILABLE_RESULT_CENTERS = gql`
query GetAll($all: Boolean) {    
  resultCenters(all: $all) {
    ...ParentResultCenter
  }
}
${parentResultCenterFragment}
`;
export const GQL_RESULT_CENTER_STORE = gql`
  mutation StoreResultCenter($attributes: ResultCenterAttributes!) {
    storeResultCenter(input: {attributes: $attributes}) { 
      resultCenter {
        ...ParentResultCenter
      }, 
      errors 
    } 
  }
  ${parentResultCenterFragment}   
`;
export const GQL_RESULT_CENTER_INACTIVATE = gql`
  mutation Inactivate($id: ID!) { 
    inactivate(input: {id: $id, className: "Financial::ResultCenter"}) {
      id,
      discardedAt
    }
  }
`;