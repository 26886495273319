import gql from "graphql-tag";
import { personFragment } from 'admin-front';
import { paymentConditionFragment } from "./PaymentCondition";
import { paymentMethodConditionFragment } from "./PaymentMethodCondition";
import { simpleCurrentAssetFragment } from "./SimpleCurrentAsset";
export const paymentMethodFragment = gql`
  fragment PaymentMethod on PaymentMethod  {
    id,
    name,
    kind,
    fees,
    discount,
    integrationMethod,
    creditCardBrand,
    paymentInstitution {
      ...Person
    },
    paymentConditions {
      ...PaymentCondition
    },
    paymentMethodConditions {
      ...PaymentMethodCondition
    },
    currentAssetId
    currentAsset {
      ...SimpleCurrentAsset,
    },
    discardedAt
  }
  ${personFragment}
  ${paymentConditionFragment}
  ${paymentMethodConditionFragment}
  ${simpleCurrentAssetFragment}
`;