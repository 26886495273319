export const MODULE = 'module';
export const PAGE = 'page';
export const ACTION = 'action';
export const REPORTS = 'reports';
export const ACTIVE = 'active';
export const DISABLED = 'disabled';
export const NEW_RESOURCE = 'new_resource';
export const UNDER_CONSTRUCTION = 'under_construction';
export const UNAVAILABLE = 'unavailable';
export const TENANT_ADMIN = 'tenant_admin';
export const SUPER_ADMIN = 'super_admin';
export const STAFF = 'staff';
export const REGULAR = 'regular';