export const reloadWindow = () => {
  window.location.reload();
};
export const hardReload = () => {
  reloadWindow();
};
export const softReload = (record, datasource) => {
  if (!record) {
    hardReload();
    return;
  }
  const object = datasource.find(object => object.id === record.id);
  if (object) {
    let index = datasource.findIndex(object => object.id === record.id);
    if (index >= 0) datasource[index] = record;
  } else {
    if (record.parentId) parentSoftReload(record, datasource);else datasource.push(record);
  }
};
const parentSoftReload = (record, datasource) => {
  let parentOnDatasource = datasource.find(object => object.id === record.parentId);
  if (!parentOnDatasource) return;
  const index = parentOnDatasource.children.findIndex(object => object.id === record.id);
  if (index >= 0) parentOnDatasource.children[index] = record;else parentOnDatasource.children.push(record);
  softReload(parentOnDatasource, datasource);
};