export const CHECKING_ACCOUNT = 'checking_account';
export const SAVINGS_ACCOUNT = 'savings_account';
export const SALARY_ACCOUNT = 'salary_account';
export const DIGITAL_ACCOUNT = 'digital_account';
export const UNIVERSITY_ACCOUNT = 'university_account';
export const INVESTMENT_ACCOUNT = 'investment_account';
export const SIMPLE_WITH_REGISTRATION = 'simple_with_registration';
export const GUARANTEED_COLLATERAL = 'guaranteed_collateral';
export const BANK_ACCOUNT_KIND_OPTIONS = [{
  value: CHECKING_ACCOUNT,
  label: 'Conta corrente'
}, {
  value: SAVINGS_ACCOUNT,
  label: 'Conta poupança'
}, {
  value: SALARY_ACCOUNT,
  label: 'Conta salário'
}, {
  value: DIGITAL_ACCOUNT,
  label: 'Conta digital'
}, {
  value: UNIVERSITY_ACCOUNT,
  label: 'Conta universitária'
}, {
  value: INVESTMENT_ACCOUNT,
  label: 'Conta de investimento'
}];
export const WALLET_OPTIONS = [{
  value: SIMPLE_WITH_REGISTRATION,
  label: 'Simples Com Registro'
}, {
  value: GUARANTEED_COLLATERAL,
  label: 'Garantida Caucionada'
}];