import { MONTH } from "../constants/paymentConditions/PaymentConditionsFieldsOptions";
import { dateOrToday, today } from 'grifo-components';
import { installmentsBuilder } from "./InstallmentsBuilder";
export const accountPaymentBuilder = (record, clearId = false) => {
  const account = record || {};
  return account.payments ? account.payments.map((object, index) => {
    delete object.condition;
    delete object.methodKind;
    delete object.paymentMethod;
    delete object.paymentCondition;
    object.amount = 0;
    return {
      ...object,
      ...{
        id: clearId ? undefined : object.id,
        installments: newInstallmentsBuilder(object, dateOrToday(object.date ? object.date : account.dueDate))
      }
    };
  }) : newAccountPaymentBuilder(record);
};
export const accountPaymentCompleteBuilder = (record, clearId = false) => {
  const object = record || {};
  return object.payments ? object.payments.map((object, index) => {
    object.amount = 0;
    return {
      ...object,
      ...{
        id: clearId ? undefined : object.id,
        installments: newInstallmentsBuilder(object, dateOrToday(object.date || record.dueDate))
      }
    };
  }) : newAccountPaymentBuilder(record);
};
export const newAccountPaymentBuilder = record => {
  const object = record || {};
  return object.payments || [{
    methodFees: 0,
    methodFeesRate: 0,
    conditionFees: 0,
    conditionFeesRate: 0,
    fees: 0,
    methodDiscount: 0,
    methodDiscountRate: 0,
    conditionDiscount: 0,
    conditionDiscountRate: 0,
    discount: 0,
    total: 0,
    amount: 0,
    time: 1,
    timeKind: MONTH,
    installment: 1,
    gracePeriod: 0,
    installments: newInstallmentsBuilder(object)
  }];
};
export const newInstallmentsBuilder = (object, date) => {
  return installmentsBuilder(object.installment, object.total, date || today(), object.time, object.timeKind, object.gracePeriod, object.amount);
};