import { BANK, CASHIER, CREDIT_CARD_COMPANY } from "../currentAssets/CurrentAssetsFieldsOptions";
export const MONEY = 'money';
export const CHECK = 'check';
export const CREDIT_CARD = 'credit_card';
export const DEBIT_CARD = 'debit_card';
export const IN_STORE_CREDIT = 'in_store_credit';
export const FOOD_VOUCHER = 'food_voucher';
export const MEAL_VOUCHER = 'meal_voucher';
export const GIFT_VOUCHER = 'gift_voucher';
export const FUEL_VOUCHER = 'fuel_voucher';
export const BANK_SLIP = 'bank_slip';
export const BANK_DEPOSIT = 'bank_deposit';
export const PIX = 'pix';
export const BANK_TRANSFER = 'bank_transfer';
export const DIGITAL_WALLET = 'digital_wallet';
export const FIDELITY_PROGRAM = 'fidelity_program';
export const CASHBACK = 'cashback';
export const VIRTUAL_CREDIT = 'virtual_credit';
export const DUPLICATE = 'duplicate';
export const PROMISSORY_NOTE = 'promissory_note';
export const INTEGRATED = 'integrated';
export const NOT_INTEGRATED = 'not_integrated';
export const VISA = 'visa';
export const MASTERCARD = 'mastercard';
export const AMERICAN_EXPRESS = 'american_express';
export const SOROCRED = 'sorocred';
export const DINERS_CLUB = 'diners_club';
export const ELO = 'elo';
export const HIPERCARD = 'hipercard';
export const AURA = 'aura';
export const CABAL = 'cabal';
export const OTHERS = 'others';
export const METHODS_OPTIONS_BY_KEY = [{
  value: '1',
  label: 'Dinheiro'
}, {
  value: '2',
  label: 'Cheque'
}, {
  value: '3',
  label: 'Cartão de Crédito'
}, {
  value: '4',
  label: 'Cartão de Débito'
}, {
  value: '5',
  label: 'Crédito na loja'
}, {
  value: '10',
  label: 'Vale alimentação'
}, {
  value: '11',
  label: 'Vale refeição'
}, {
  value: '12',
  label: 'Vale presente'
}, {
  value: '13',
  label: 'Vale combustível'
}, {
  value: '15',
  label: 'Boleto bancário'
}, {
  value: '16',
  label: 'Depósito bancário'
}, {
  value: '17',
  label: 'PIX'
}, {
  value: '18',
  label: 'Transferência bancária'
}, {
  value: '28',
  label: 'Carteira Digital'
}, {
  value: '19',
  label: 'Programa de fidelidade'
}, {
  value: '29',
  label: 'Cashback'
}, {
  value: '30',
  label: 'Crédito Virtual'
}, {
  value: '80',
  label: 'Duplicata'
}, {
  value: '81',
  label: 'Promissória'
}, {
  value: '99',
  label: 'Outros'
}];
export const METHODS_OPTIONS = [{
  value: MONEY,
  label: 'Dinheiro'
}, {
  value: CHECK,
  label: 'Cheque'
}, {
  value: CREDIT_CARD,
  label: 'Cartão de Crédito'
}, {
  value: DEBIT_CARD,
  label: 'Cartão de Débito'
}, {
  value: IN_STORE_CREDIT,
  label: 'Crédito na loja'
}, {
  value: FOOD_VOUCHER,
  label: 'Vale alimentação'
}, {
  value: MEAL_VOUCHER,
  label: 'Vale refeição'
}, {
  value: GIFT_VOUCHER,
  label: 'Vale presente'
}, {
  value: FUEL_VOUCHER,
  label: 'Vale combustível'
}, {
  value: BANK_SLIP,
  label: 'Boleto bancário'
}, {
  value: BANK_DEPOSIT,
  label: 'Depósito bancário'
}, {
  value: PIX,
  label: 'PIX'
}, {
  value: BANK_TRANSFER,
  label: 'Transferência bancária'
}, {
  value: DIGITAL_WALLET,
  label: 'Carteira Digital'
}, {
  value: FIDELITY_PROGRAM,
  label: 'Programa de fidelidade'
}, {
  value: CASHBACK,
  label: 'Cashback'
}, {
  value: VIRTUAL_CREDIT,
  label: 'Crédito Virtual'
}, {
  value: DUPLICATE,
  label: 'Duplicata'
}, {
  value: PROMISSORY_NOTE,
  label: 'Promissória'
}];
export const INTEGRATION_METHOD_OPTIONS = [{
  value: INTEGRATED,
  label: 'Pagamento integrado com o sistema de automação da empresa (Ex.: equipamento TEF, Comércio Eletrônico)'
}, {
  value: NOT_INTEGRATED,
  label: 'Pagamento NÃO integrado com o sistema de automação da empresa (Ex.: equipamento POS)'
}];
export const CREDIT_CARD_BRAND = [{
  value: VISA,
  label: 'Visa'
}, {
  value: MASTERCARD,
  label: 'Mastercard'
}, {
  value: AMERICAN_EXPRESS,
  label: 'American Express'
}, {
  value: SOROCRED,
  label: 'Sorocred'
}, {
  value: DINERS_CLUB,
  label: 'Diners Club'
}, {
  value: ELO,
  label: 'Elo'
}, {
  value: HIPERCARD,
  label: 'Hipercard'
}, {
  value: AURA,
  label: 'Aura'
}, {
  value: CABAL,
  label: 'Cabal'
}, {
  value: OTHERS,
  label: 'Outros'
}];
export const CURRENT_ASSET_BY_PAYMENT_METHOD_KIND = [{
  value: MONEY,
  options: [CASHIER]
}, {
  value: CHECK,
  options: [CASHIER, BANK]
}, {
  value: CREDIT_CARD,
  options: [CREDIT_CARD_COMPANY, BANK]
}, {
  value: DEBIT_CARD,
  options: [BANK]
}, {
  value: FOOD_VOUCHER,
  options: [CREDIT_CARD_COMPANY, CASHIER]
}, {
  value: MEAL_VOUCHER,
  options: [CREDIT_CARD_COMPANY, CASHIER]
}, {
  value: BANK_SLIP,
  options: [BANK]
}, {
  value: BANK_DEPOSIT,
  options: [BANK]
}, {
  value: PIX,
  options: [BANK]
}, {
  value: BANK_TRANSFER,
  options: [BANK]
}];