import gql from "graphql-tag";
import { bankFragment } from "../fragments/Bank";
export const GQL_BANKS_LIST = gql`
  query GetByName($name: String) {    
    banks(name: $name) {
      ...Bank
    }
  }
  ${bankFragment}
`;
export const GQL_BANK_ID_SEARCH = gql`
  query Get($id: ID) {    
    banks(id: $id) {
      ...Bank
    }
  }
  ${bankFragment}
`;