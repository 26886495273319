import gql from "graphql-tag";
import { phaseFragment } from "../fragments/Phase";
export const GQL_OPEN_PHASE = gql`
  mutation OpenPhase($id: ID!, $minutes: String) { 
    openPhase(input: {id: $id, minutes: $minutes}) {
      id,
      errors
    }
  }
`;
export const GQL_CLOSE_PHASE = gql`
  mutation ClosePhase($id: ID!, $minutes: String) { 
    closePhase(input: {id: $id, minutes: $minutes}) {
      id,
      errors
    }
  }
`;
export const GQL_PAUSE_PHASE = gql`
  mutation PausePhase($id: ID!, $minutes: String) { 
    pausePhase(input: {id: $id, minutes: $minutes}) {
      id,
      errors
    }
  }
`;
export const GQL_REOPEN_PHASE = gql`
  mutation ReopenPhase($id: ID!, $minutes: String) { 
    reopenPhase(input: {id: $id, minutes: $minutes}) {
      id,
      errors
    }
  }
`;
export const GQL_SKIP_PHASE = gql`
  mutation SkipPhase($id: ID!, $minutes: String) { 
    skipPhase(input: {id: $id, minutes: $minutes}) {
      id,
      errors
    }
  }
`;
export const GQL_PHASES_LIST = gql`
  query GetPhases($assemblyId: ID!) {    
    phasesByAssembly(assemblyId: $assemblyId) { 
      ...Phase
    }
  }
  ${phaseFragment}
`;