import { concatDateTime, dateOrToday, timeOrNow } from 'grifo-components';
export const currentAssetOpeningParamBuilder = (record, values, currentAsset = undefined) => {
  return {
    attributes: {
      id: record ? record.id : null,
      currentAssetId: currentAsset.id,
      currentAssetOpeningId: values.kind === "closure" ? currentAsset.currentAssetActualOpening.id : null,
      kind: values.kind,
      date: concatDateTime(values.date, values.hour)._i,
      notes: values.notes
    }
  };
};
export const currentAssetOpeningDefaultBuilder = record => {
  return {
    currentAssetId: (record || {}).currentAssetId,
    kind: (record || {}).kind || "opening",
    date: dateOrToday((record || {}).date),
    hour: timeOrNow((record || {}).date),
    notes: (record || {}).notes
  };
};
export const currentAssetClosureDefaultBuilder = record => {
  return {
    currentAssetId: (record || {}).currentAssetId,
    kind: (record || {}).kind || "closure",
    date: dateOrToday((record || {}).date),
    hour: timeOrNow((record || {}).date),
    notes: (record || {}).notes
  };
};