import { first, last, round } from 'grifo-components';
import { IN_CASH, MONTH } from "../constants/paymentConditions/PaymentConditionsFieldsOptions";
import { installmentsBuilder } from "../builders/InstallmentsBuilder";
export const onValueChange = (value, form, referenceDate, onCalculation) => {
  if ((form.getFieldsValue().payments || []).length === 1) calculatePaymentValue(value, 0, form, referenceDate, onCalculation);
};
export const setPaymentMethodValues = (paymentMethod, index, form, referenceDate, onCalculation) => {
  let payments = form.getFieldsValue().payments || {};
  (payments[index] || {}).methodFeesRate = paymentMethod.fees || 0;
  (payments[index] || {}).methodDiscountRate = paymentMethod.discount || 0;
  (payments[index] || {}).methodKind = paymentMethod.kind;
  form.setFieldsValue({
    payments: payments
  });
  calculatePaymentValue((payments[index] || {}).valueToPay, index, form, referenceDate, onCalculation);
};
export const setPaymentConditionValues = (paymentCondition, index, form, referenceDate, onCalculation) => {
  let payments = form.getFieldsValue().payments || [];
  (payments[index] || {}).conditionFeesRate = paymentCondition.fees;
  (payments[index] || {}).conditionDiscountRate = paymentCondition.discount;
  (payments[index] || {}).time = paymentCondition.time || 1;
  (payments[index] || {}).timeKind = paymentCondition.timeKind || MONTH;
  (payments[index] || {}).installment = paymentCondition.installments || 1;
  (payments[index] || {}).gracePeriod = paymentCondition.gracePeriod || 0;
  (payments[index] || {}).condition = paymentCondition.condition || IN_CASH;
  form.setFieldsValue({
    payments: payments
  });
  calculatePaymentValue((payments[index] || {}).valueToPay, index, form, referenceDate, onCalculation);
};
export const calculatePaymentValue = (value, index, form, referenceDate, onCalculation) => {
  let payments = form.getFieldsValue().payments || [];
  let payment = payments[index];
  if (!payment) return;
  payment.valueToPay = value;
  const methodFees = round(payment.valueToPay * (payment.methodFeesRate || 0) / 100);
  const conditionFees = round(payment.valueToPay * (payment.conditionFeesRate || 0) / 100);
  payment.methodFees = methodFees;
  payment.conditionFees = conditionFees;
  payment.fees = round(methodFees + conditionFees);
  const methodDiscount = round(payment.valueToPay * (payment.methodDiscountRate || 0) / 100);
  const conditionDiscount = round(payment.valueToPay * (payment.conditionDiscountRate || 0) / 100);
  payment.methodDiscount = methodDiscount;
  payment.conditionDiscount = conditionDiscount;
  payment.discount = methodDiscount + conditionDiscount;
  const valueToPay = payment.valueToPay || 0;
  const fees = payment.fees || 0;
  const discount = payment.discount || 0;
  payment.amount = valueToPay;
  payment.total = round(valueToPay + fees - discount);
  form.setFieldsValue({
    payments: payments
  });
  generateInstallments({
    installments: payment.installment,
    time: payment.time,
    timeKind: payment.timeKind,
    gracePeriod: payment.gracePeriod,
    condition: payment.condition
  }, index, form, referenceDate);
  if (onCalculation) onCalculation(form);
};
export const generateInstallments = (paymentCondition, index, form, referenceDate) => {
  let payments = form.getFieldsValue().payments;
  let previousCondition;
  let previousDue = payments.length > 1 ? (last((payments[index > 0 ? index - 1 : index] || {}).installments || []) || {}).dueDate : (first((payments[index > 0 ? index - 1 : index] || {}).installments || []) || {}).dueDate;
  if (!previousDue || previousDue && previousCondition != paymentCondition) previousDue = referenceDate;
  previousCondition = paymentCondition;
  payments[index].installments = installmentsBuilder(paymentCondition.installments, payments[index].total, previousDue, paymentCondition.time, paymentCondition.timeKind, paymentCondition.gracePeriod, payments[index].valueToPay, paymentCondition.condition);
  form.setFieldsValue({
    payments: payments
  });
};
export const generateInstallments2 = (form, payments, index, installment, referenceDate) => {
  form.setFieldsValue({
    installment: installment
  });
  let previousDue = payments.length > 1 ? (last((payments[index > 0 ? index - 1 : index] || {}).installments || []) || {}).dueDate : (first((payments[index > 0 ? index - 1 : index] || {}).installments || []) || {}).dueDate;
  if (!previousDue) previousDue = referenceDate;
  let installments = installmentsBuilder(form.getFieldsValue().installment, payments[index].total, previousDue, form.getFieldsValue().time, form.getFieldsValue().timeKind, form.getFieldsValue().gracePeriod, form.getFieldsValue().amount || form.getFieldsValue().installments[index].amount);
  form.setFieldsValue({
    installments: installments
  });
};