import gql from "graphql-tag";
import { companyFragment } from 'admin-front';
import { operationFragment } from "./Operation";
import { currentAssetOpeningFragment } from "./CurrentAssetOpening";
export const simpleCurrentAssetFragment = gql`
  fragment SimpleCurrentAsset on CurrentAsset  { 
    id,
    name,
    kind,
    controlsOpening,
    opened,
    userId,
    discardedAt,
    company {
      ...Company
    },
    operation {
      ...Operation      
    }
    currentAssetOpenings {
      ...CurrentAssetOpening
    }
    currentAssetActualOpening {
      ...CurrentAssetOpening
    }
  }
  ${companyFragment}
  ${operationFragment}
  ${currentAssetOpeningFragment}
`;