import { MAIN } from 'admin-front';
import { sanitizeBackend, dateOrToday, sanitize } from 'grifo-components';
export const administrativeCouncilParamBuilder = (record, values) => {
  let params = sanitize(values, ["city", "contact"]);
  if (params.phone) params.phone.naming = "main";
  if (params.address) params.address.naming = MAIN;
  return {
    attributes: {
      id: record ? record.id : null,
      role: values.role,
      start: values.start,
      finish: values.finish,
      personId: values.personId
    }
  };
};
export const administrativeCouncilDefaultBuilder = record => {
  const start = (record || {}).start;
  const finish = (record || {}).finish;
  return {
    role: (record || {}).role,
    start: dateOrToday(start),
    finish: sanitizeBackend(finish),
    person: (record || {}).person,
    personId: ((record || {}).person || {}).id,
    name: ((record || {}).person || {}).name,
    tradeName: ((record || {}).person || {}).tradeName,
    document: ((record || {}).person || {}).document,
    photo64: ((record || {}).person || {}).photo64,
    email: ((record || {}).person || {}).email,
    phone: (record || {}).phone,
    phoneNumber: (((record || {}).person || {}).phone || {}).number,
    phoneAreaCode: (((record || {}).person || {}).phone || {}).areaCode,
    phoneExtension: (((record || {}).person || {}).phone || {}).extension,
    address: (record || {}).address
  };
};