export const slateMemberParamBuilder = (record, values) => {
  return {
    slateMembers: values.slateMembers.map(object => ({
      ...object,
      slateId: record.id
    })),
    slateId: record.id,
    personId: record.personId
  };
};
export const slateMemberDefaultBuilder = record => {
  return {
    slateMembers: record || []
  };
};