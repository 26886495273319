export const participantsParamBuilder = (record, values) => {
  return {
    participants: values.participants.map(object => ({
      ...object,
      assemblyId: record.id
    })),
    assemblyId: record.id,
    personId: record.personId
  };
};
export const participantsDefaultBuilder = record => {
  let object = record || {};
  return {
    participants: record || [],
    canVote: object.canVote,
    voteOfMinerva: object.voteOfMinerva,
    personId: object.personId
  };
};