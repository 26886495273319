import gql from "graphql-tag";
import { attendanceFragment } from "../fragments/Attendance";
export const GQL_ATTENDANCE_LIST = gql`
  {
    attendance { 
      ...Attendance
    }
  }
  ${attendanceFragment}
`;
export const GQL_ATTENDANCE_STORE = gql`
  mutation StoreAttendance($attributes: AttendanceAttributes!) {
    storeAttendance(input: {attributes: $attributes}) { 
      attendances {
        ...Attendance
    }, 
      errors 
    } 
  }
  ${attendanceFragment}
`;
export const GQL_STORE_PRESENCE = gql`
  mutation StorePresence($assemblyId: ID!, $personId: ID!) {
    storePresence(input: {assemblyId: $assemblyId, personId: $personId}) { 
      attendance {
        ...Attendance
    }, 
      errors 
    } 
  }
  ${attendanceFragment}
`;