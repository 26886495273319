import gql from "graphql-tag";
import { electionFragment } from "../fragments/Election";
import { electionEventFragment } from "../fragments/ElectionEvent";
import { electionVotesFragment } from "../fragments/ElectionVotes";
export const GQL_ELECTION_EVENT_LIST = gql`
  {
    electionsEvent { 
      ...ElectionEvent
    }
  }
  ${electionEventFragment}
`;
export const GQL_ELECTION_REALIZATION_LIST = gql`
  {
    electionsRealization { 
      ...ElectionRealization
    }
  }
  ${electionFragment}
`;
export const GQL_ELECTION_EVENT_STORE = gql`
  mutation StoreElectionEvent($attributes: ElectionEventAttributes!) {
    storeElectionEvent(input: {attributes: $attributes}) { 
        electionEvent {
        ...ElectionEvent
    }, 
      errors 
    } 
  }
  ${electionEventFragment}
`;
export const GQL_ELECTION_OPEN = gql`
  mutation OpenElectionEvent($id: ID!) { 
    openElectionEvent(input: {id: $id}) {
      id
    }
  }
`;
export const GQL_ELECTION_CLOSE = gql`
  mutation CloseElectionEvent($attributes: OfflineElectionVotesAttributes!) { 
    closeElectionEvent(input: {attributes: $attributes}) {
      electionVotes {
        ...ElectionVotes
      }
      errors
    }
  }
  ${electionVotesFragment}
`;