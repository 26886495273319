import { sanitize } from 'grifo-components';
import { FISCAL } from "../constants/slates/SlateOptions";
export const slateParamBuilder = (record, values) => {
  let slateMembers = values.slateMembers ? sanitize(values.slateMembers, ['person']) : null;
  let documents64 = values.documents64 ? sanitize(values.documents64, ['name', 'url']) : null;
  return {
    attributes: {
      id: record ? record.id : null,
      name: values.name,
      councilType: values.councilType,
      description: values.description,
      documents64: documents64,
      slateMembers: slateMembers
    }
  };
};
export const slateDefaultBuilder = record => {
  const object = record || {};
  return {
    name: object.name,
    councilType: object.councilType || FISCAL,
    description: object.description,
    documents64: (object.documents64 || []).map(file => ({
      name: file.filename,
      status: 'done',
      uid: file.id,
      key: file.key,
      url: file.key,
      filename: file.filename,
      contentType: file.contentType,
      data: file.data
    })),
    slateMembers: object.slateMembers,
    canChange: object.canChange
  };
};