import gql from "graphql-tag";
import { paymentConditionFragment } from "./PaymentCondition";
import { paymentMethodFragment } from "./PaymentMethod";
import { bankSlipFragment } from "./BankSlip";
export const AccountPaymentFragment = gql`
  fragment AccountPayment on AccountPayment  { 
    id,
    timeKind,
    time,
    installment,
    gracePeriod,
    condition,
    methodKind,
    valueToPay,
    fees,
    discount,
    total,
    methodFees,
    conditionFees,
    methodDiscount,
    conditionDiscount,
    methodFeesRate,
    conditionFeesRate,
    methodDiscountRate,
    conditionDiscountRate,
    paymentMethodId,
    paymentConditionId,
    bankSlip{
      ...BankSlip
    },
    paymentMethod {
      ...PaymentMethod
    },    
    paymentCondition {
      ...PaymentCondition
    },    
  }
  ${paymentMethodFragment} 
  ${bankSlipFragment} 
  ${paymentConditionFragment}   
`;