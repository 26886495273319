import gql from "graphql-tag";
import { administrativeCouncilFragment } from "../fragments/AdministrativeCouncil";
export const GQL_ADMINISTRATIVE_COUNCILS_LIST = gql`
  query GetAll($pageSize: Int, $cursor: String) {    
    administrativeCouncilsPaginated(first: $pageSize, after: $cursor) {
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasPreviousPage
        hasNextPage
      } 
      edges { 
        cursor
        node {
          ...AdministrativeCouncil
        }
      }
    }    
  }
  ${administrativeCouncilFragment}
`;
export const GQL_ADMINISTRATIVE_COUNCIL_STORE = gql`
  mutation StoreAdministrativeCouncil($attributes: AdministrativeCouncilAttributes!) {
    storeAdministrativeCouncil(input: {attributes: $attributes}) { 
        administrativeCouncil {
        ...AdministrativeCouncil
    }, 
      errors 
    } 
  }
  ${administrativeCouncilFragment}   
`;