import gql from "graphql-tag";
export const GQL_OPEN_POLL = gql`
  mutation OpenPoll($id: ID!, $minutes: String) { 
    openPoll(input: {id: $id, minutes: $minutes}) {
      id,
      errors
    }
  }
`;
export const GQL_CLOSE_POLL = gql`
  mutation ClosePoll($id: ID!, $minutes: String, $offlineAgree: Int, $offlineDisagree: Int, $offlineAbstain: Int) { 
    closePoll(input: {id: $id, minutes: $minutes,offlineAgree: $offlineAgree, offlineDisagree: $offlineDisagree, offlineAbstain: $offlineAbstain}) {
      id,
      errors
    }
  }
`;
export const GQL_AWAIT_POLL = gql`
  mutation AwaitPoll($id: ID!, $minutes: String) { 
    awaitPoll(input: {id: $id, minutes: $minutes}) {
      id,
      errors
    }
  }
`;
export const GQL_SKIP_POLL = gql`
  mutation SkipPoll($id: ID!, $minutes: String) { 
    skipPoll(input: {id: $id, minutes: $minutes}) {
      id,
      errors
    }
  }
`;