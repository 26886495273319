import gql from "graphql-tag";
import { slateFragment } from "./Slate";
export const electionTotalFragment = gql`
  fragment ElectionTotal on ElectionTotal  { 
    electionId,
    totalVotes,
    totalAbstention,
    slateVotes,
    votesPercentage,
    totalSlateVotes,
    slate {
      ...Slate
    }
  }
  ${slateFragment}
`;