import { today, sanitize } from 'grifo-components';
import { ADDITION, DEDUCTION, WRITE_OFF } from '../constants/operations/OperationsFieldsOptions';
const compensationsAccounts = (values, documents64) => {
  let data = [];
  let accounts = values.compensationsAccounts || [];
  for (let index = 0; index < accounts.length; index++) {
    const compensationsAccount = accounts[index];
    let writeOff = {
      movements: [{
        accountId: compensationsAccount.accountId,
        operationId: values.operationId,
        value: compensationsAccount.value,
        kind: WRITE_OFF,
        date: values.date.format("YYYY-MM-DD"),
        shortComplement: values.shortComplement,
        longComplement: values.longComplement,
        referencedDocument: values.referencedDocument,
        documents64: documents64,
        currentAssetId: values.currentAssetId
      }],
      accountId: compensationsAccount.accountId
    };
    data.push(writeOff);
  }
  return data.length === 0 ? null : data;
};
export const movementParamBuilder = (record, values) => {
  let movements = [];
  let documents64 = values.documents64 ? sanitize(values.documents64, ['name', 'url']) : null;
  let main_movement = {
    accountId: record.id,
    operationId: values.operationId,
    kind: values.kind,
    value: values.value,
    date: values.date.format("YYYY-MM-DD"),
    shortComplement: values.shortComplement,
    longComplement: values.longComplement,
    referencedDocument: values.referencedDocument,
    documents64: documents64,
    currentAssetId: values.currentAssetId
  };
  movements.push(main_movement);
  if (values.latePaymentFineValue > 0 && values.grantLatePaymentFine) {
    movements.push({
      accountId: record.id,
      operationId: record.operation.latePaymentFineOperationId,
      kind: ADDITION,
      value: values.latePaymentFineValue,
      date: values.date.format("YYYY-MM-DD"),
      shortComplement: 'Mora'
    });
  }
  if (values.latePaymentInterestValue > 0 && values.grantLatePaymentInterest) {
    movements.push({
      accountId: record.id,
      operationId: record.operation.latePaymentInterestOperationId,
      kind: ADDITION,
      value: values.latePaymentInterestValue,
      date: values.date.format("YYYY-MM-DD"),
      shortComplement: 'Juros'
    });
  }
  if (values.additionValue > 0) {
    movements.push({
      accountId: record.id,
      operationId: values.additionOperationId,
      kind: ADDITION,
      value: values.additionValue,
      date: values.date.format("YYYY-MM-DD"),
      shortComplement: 'Acréscimo avulso na baixa'
    });
  }
  if (values.earlyPaymentDeductionValue > 0 && values.grantEarlyPaymentDeduction) {
    movements.push({
      accountId: record.id,
      operationId: record.operation.earlyPaymentOperationId,
      kind: DEDUCTION,
      value: values.earlyPaymentDeductionValue,
      date: values.date.format("YYYY-MM-DD"),
      shortComplement: 'Desconto por pagamento antecipado'
    });
  }
  if (values.deductionValue > 0) {
    movements.push({
      accountId: record.id,
      operationId: values.deductionOperationId,
      kind: DEDUCTION,
      value: values.deductionValue,
      date: values.date.format("YYYY-MM-DD"),
      shortComplement: 'Desconto avulso na baixa'
    });
  }
  let compensation = compensationsAccounts(values, documents64);
  let data = {
    accountId: record.id,
    movements: movements,
    compensationsAccounts: compensation
  };
  return data;
};
export const movementDefaultBuilder = record => {
  (record.movements || []).forEach(element => {
    element.documents64 = (element.documents64 || []).map(file => ({
      name: file.filename,
      status: 'done',
      uid: file.id,
      key: file.key,
      url: file.key,
      filename: file.filename,
      contentType: file.contentType,
      data: file.data
    }));
  });
  return {
    grantLatePaymentInterest: true,
    grantLatePaymentFine: true,
    grantEarlyPaymentDeduction: true,
    latePaymentFineValue: record.latePaymentFineValue,
    latePaymentInterestValue: record.latePaymentInterestValue,
    earlyPaymentDeductionValue: record.earlyPaymentDeductionValue,
    deductionValue: 0.0,
    additionValue: 0.0,
    accountId: record.id,
    transactionId: record.transactionId,
    operationId: record.operation.writeOffId,
    value: 0.0,
    compensationsAccounts: [],
    date: today(),
    shortComplement: ""
  };
};