import { isSuperAdmin, isTenantAdmin } from 'admin-front';
import { BLOCKED, GRANTED, HIDDEN, READ_ONLY } from "../constants/Authorization";
import { isOnlyForStaff, isOnlyForSuperAdmin, isOnlyForTenantAdmin, isSkipAuthorization } from "./FeatureUtils";
const getAuth = (key, session) => {
  return (session?.authorizations || []).filter(auth => auth.resourceKey === key)[0];
};
export const isUserUnauthorized = (feature, session) => {
  return !isSkipAuthorization(feature) && (isUserBlocked(feature, session) || isUserHidden(feature, session) || !isUserAuthorized(feature, session));
};
export const isUserAuthorized = (feature, session) => {
  return isSkipAuthorization(feature) || isTenantAdmin(session) || isSuperAdmin(session) || getAuth(feature.key, session)?.kind === GRANTED;
};
export const isUserBlocked = (feature, session) => {
  return !isTenantAdmin(session) && !isSuperAdmin(session) && getAuth(feature.key, session)?.kind === BLOCKED;
};
export const isUserHidden = (feature, session) => {
  let shouldHide = isOnlyForSuperAdmin(feature) && !isSuperAdmin(session);
  if (!shouldHide) shouldHide = isOnlyForStaff(feature) && !isSuperAdmin(session);
  if (!shouldHide) shouldHide = isOnlyForTenantAdmin(feature) && !(isTenantAdmin(session) || isSuperAdmin(session));
  if (!shouldHide) shouldHide = getAuth(feature.key, session)?.kind === HIDDEN;
  return shouldHide;
};
export const isUserReadOnly = (feature, session) => {
  return !isTenantAdmin(session) && !isSuperAdmin(session) && getAuth(feature.key, session)?.kind === READ_ONLY;
};