import gql from "graphql-tag";
import { cooperativeCompanyOrganizationFragment } from "../fragments/CooperativeCompanyOrganization";
import { organizationParentFragment } from "../fragments/OrganizationParent";
export const GQL_SEARCH_ORGANIZATIONS = gql`
  query GetByName($name: String) {    
    organizations(name: $name) { 
        ...OrganizationParent
    }
  }
  ${organizationParentFragment}   
`;
export const GQL_SEARCH_BY_PARAMS = gql`
  query GetByLevel($params: Hash) {    
    organizations(params: $params) { 
        ...OrganizationParent
    }
  }
  ${organizationParentFragment}   
`;
export const GQL_SEARCH_BY_LEVEL = gql`
  query GetByLevel($level: String) {    
    organizations(level: $level) { 
        ...OrganizationParent
    }
  }
  ${organizationParentFragment}   
`;
export const GQL_ORGANIZATIONS_LIST = gql`
  {
    organizations { 
      ...OrganizationParent
    }
  }
  ${organizationParentFragment}   
`;
export const GQL_ORGANIZATION_COMPANY_SEARCH = gql`
  query GetByCompany($companyId: ID) {    
    organizations(companyId: $companyId) {
      ...OrganizationParent
    }
  }
  ${organizationParentFragment}
  `;
export const GQL_ORGANIZATION_ID_SEARCH = gql`
  query GetById($id: ID) {    
    organizations(id: $id) {
      ...OrganizationParent
    }
  }
  ${organizationParentFragment}
`;
export const GQL_ORGANIZATION_STORE = gql`
  mutation StoreOrganization($attributes: OrganizationAttributes!) {
    storeOrganization(input: {attributes: $attributes}) { 
      organization {
        ...CooperativeCompanyOrganization
    }, 
      errors 
    } 
  }
  ${cooperativeCompanyOrganizationFragment}   
`;
export const GQL_ORGANIZATION_INACTIVATE = gql`
  mutation Inactivate($id: ID!) { 
    inactivate(input: {id: $id, className: "Cooperative::Organization"}) {
    id
    }
  }
`;