export const onlyAttributeName = field => {
  const occurrences = field.split(".").length - 1;
  return field.includes(".") ? field.split('.')[occurrences] : camelizeUnderscore(field);
};
export const camelizeUnderscore = text => {
  return text.replace(/^([A-Z])|[\s-_]+(\w)/g, function (match, p1, p2, offset) {
    if (p2) return p2.toUpperCase();
    return p1.toLowerCase();
  });
};
export const eachStringFirstLetter = name => {
  if (!name) return;
  const completeName = name.toUpperCase();
  name = (completeName.match(/\b(\w)/g) || []).slice(0, 3).join('');
  return name;
};
export const capitalizeEachWord = string => {
  return string.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
};