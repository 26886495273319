export const accountPaymentInstallmentsDefaultBuilder = record => {
  const object = record || {};
  return {
    total: object.total,
    time: object.time,
    installment: object.installment,
    timeKind: object.timeKind,
    gracePeriod: object.gracePeriod,
    installments: object.installments || []
  };
};