export const GRANTED = 'granted';
export const BLOCKED = 'blocked';
export const READ_ONLY = 'read_only';
export const HIDDEN = 'hidden';
export const AUTH_OPTIONS = [{
  value: GRANTED,
  label: 'Concedido'
}, {
  value: BLOCKED,
  label: 'Bloqueado'
},
// { value: READ_ONLY, label: 'Somente leitura'},
{
  value: HIDDEN,
  label: 'Escondido'
}];