import gql from "graphql-tag";
import { personFragment } from 'admin-front';
export const slateMemberFragment = gql`
  fragment SlateMember on SlateMember  { 
    id,
    role,
    personId,
    person {
      ...Person
    }
  }
  ${personFragment}
`;