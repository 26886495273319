import { sanitizeBackend, dateOrToday } from 'grifo-components';
import { FISCAL } from "../constants/slates/SlateOptions";
export const electionParamBuilder = (record, values) => {
  return {
    attributes: {
      id: record ? record.id : null,
      councilType: values.councilType,
      start: values.start,
      finish: values.finish,
      slates: values.slates
    }
  };
};
export const electionDefaultBuilder = record => {
  record = record || {};
  const start = record.start;
  const finish = record.finish;
  return {
    start: dateOrToday(start),
    finish: sanitizeBackend(finish),
    councilType: record.councilType || FISCAL,
    canOpen: record.canOpen,
    canClose: record.canClose,
    closed: record.closed,
    date: (record.currentEvent || {}).date,
    slates: record.electionSlates
  };
};