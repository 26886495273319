import gql from "graphql-tag";
import { bankAccountFragment } from "../fragments/BankAccount";
export const GQL_SEARCH_BANK_ACCOUNTS = gql`
  query GetByName($name: String) {    
    bankAccounts(name: $name) {
      ...BankAccount
    }
  }
  ${bankAccountFragment}   
`;
export const GQL_BANK_ACCOUNTS_LIST_FROM_COMPANY = gql`
  query GetFromCompany($pageSize: Int, $cursor: String, $filter: Boolean, $bankAccountFilter: BankAccountFilter) {    
    bankAccountsPaginated(first: $pageSize, after: $cursor, filter: $filter, bankAccountFilter: $bankAccountFilter) {
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasPreviousPage
        hasNextPage
      } 
      edges { 
        cursor
        node {
          ...BankAccount
        }
      }
    }    
  }
  ${bankAccountFragment}
`;
export const GQL_BANK_ACCOUNTS_LIST = gql`
  {
    bankAccounts {
      ...BankAccount
    }
  }
  ${bankAccountFragment}   
`;
export const GQL_BANK_ACCOUNT_SEARCH_BY_ID = gql`
query byId($id: ID) { 
  bankAccounts(id: $id) { 
    ...BankAccount
  }
}
  ${bankAccountFragment}   
`;
export const GQL_BANK_ACCOUNT_SEARCH = gql`
query homologated($homologated: Boolean) { 
  bankAccounts(homologated: $homologated) { 
    ...BankAccount
  }
}
  ${bankAccountFragment}   
`;
export const GQL_BANK_ACCOUNT_STORE = gql`
  mutation StoreBankAccount($attributes: BankAccountAttributes!) {
    storeBankAccount(input: {attributes: $attributes}) { 
      bankAccount {
        ...BankAccount
    }, 
      errors 
    } 
  }
  ${bankAccountFragment}   
`;