export const reconciliationMovementParamBuilder = record => {
  return {
    attributes: (record || []).map(element => ({
      transactionId: element.transactionId,
      accountId: (element.account || {}).id,
      movementId: (element.movement || {}).id,
      value: (element.movement || {}).value,
      markForDestruction: (element || {}).markForDestruction
    }))
  };
};