import { sanitizeBackend, dateOrToday, sanitize } from 'grifo-components';
export const pollParamBuilder = (record, values) => {
  let documents64 = values.documents64 ? sanitize(values.documents64, ['name', 'url']) : null;
  return {
    attributes: {
      id: record ? record.id : null,
      start: values.start,
      finish: values.finish,
      question: values.question,
      description: values.description,
      majorityType: values.majorityType || "do_not_apply",
      minutes: values.minutes,
      documents64: documents64
    }
  };
};
export const pollDefaultBuilder = record => {
  const start = (record || {}).start;
  const finish = (record || {}).finish;
  record = record || {};
  let documents64 = ((record || {}).documents64 || []).map(file => ({
    name: file.filename,
    status: 'done',
    uid: file.id,
    key: file.key,
    url: file.key,
    filename: file.filename,
    contentType: file.contentType,
    data: file.data
  }));
  return {
    id: record.id,
    start: dateOrToday(start),
    finish: sanitizeBackend(finish),
    question: record.question,
    description: record.description,
    majorityType: record.majorityType,
    minutes: record.minutes,
    documents64: documents64,
    currentAnswer: record.currentAnswer,
    canOpen: (record || {}).canOpen,
    canClose: (record || {}).canClose,
    canSkip: (record || {}).canSkip,
    isClosed: (record || {}).isClosed,
    isSkipped: (record || {}).isSkipped,
    isPresident: (record || {}).isPresident
  };
};