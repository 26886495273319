import Cookies from "universal-cookie";
export const clearSession = () => {
  clearSessionData();
  clearSessionInfo();
};
export const clearSessionData = () => {
  new Cookies().remove("session");
};
export const clearSessionInfo = () => {
  localStorage.removeItem("sessionInfo");
};
export const setSessionData = credentials => {
  new Cookies().set("session", credentials, {
    path: "/"
  });
};
export const getSessionData = () => {
  return new Cookies().get("session");
};
export const setSessionInfo = sessionInfo => {
  localStorage.setItem('sessionInfo', JSON.stringify(sessionInfo));
};
export const getSessionInfo = () => {
  return JSON.parse(localStorage.getItem("sessionInfo"));
};
export const getCollapsedMenu = () => {
  return JSON.parse(localStorage.getItem("menuCollapsed"));
};
export const setCollapsedMenu = collapsed => {
  localStorage.setItem('menuCollapsed', collapsed);
};