import gql from "graphql-tag";
import { currentAssetFragment } from "./CurrentAsset";
import { bankFragment } from "./Bank";
import { accountSimpleFragment } from "./AccountSimple";
import { companyFragment } from 'admin-front';
import { reconciliationFragment } from "./Reconciliation";
import { agreementFragment } from "./Agreement";
export const bankAccountFragment = gql`
  fragment BankAccount on BankAccount  {
    id,
    description,
    fullDescription,
    branch,
    kind,
    number,
    ourNumber,
    canGenerateBankSlip,
    discardedAt,
    currentAsset {
      ...CurrentAsset,
    },
    bank {
      ...Bank
    },
    account {
      ...AccountSimple
    }
    company {
      ...Company
    },
    reconciliation {
      ...Reconciliation
    },
    agreements {
      ...Agreement
    },
    hasFinishedReconciliations
  }
  ${currentAssetFragment}
  ${bankFragment}
  ${accountSimpleFragment} 
  ${companyFragment}
  ${reconciliationFragment}
  ${agreementFragment}
`;