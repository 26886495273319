import gql from "graphql-tag";
export const currentAssetOpeningFragment = gql`
  fragment CurrentAssetOpening on CurrentAssetOpening  { 
    id,
    moneyBalance,
    date,
    kind,
    notes,
    user {
      id,
      profile,
      email,
      name,
    },
    currentAssetOpening {
      id,
      date,
      kind,
      notes,      
      user {
        id,
        profile,
        email,
        name,
      }
    } 
  }
`;