export const CUSTOM_TRANSLATE = [{
  value: 'kind',
  label: 'Tipo'
}, {
  value: 'balance',
  label: 'Saldo'
}, {
  value: 'reversing_movement',
  label: 'Estorno'
}, {
  value: 'additionalInformationId',
  label: 'Informação adicional'
}, {
  value: 'name',
  label: 'Nome'
}, {
  value: 'date',
  label: 'Data'
}, {
  value: 'shortComplement',
  label: 'Complemento'
}, {
  value: 'short_complement',
  label: 'Complemento'
}, {
  value: 'acronym',
  label: 'Acrônimo'
}, {
  value: 'email',
  label: 'E-mail'
}, {
  value: 'number',
  label: 'Número'
}, {
  value: 'areaCode',
  label: 'Prefixo'
}, {
  value: 'area_code',
  label: 'Prefixo'
}, {
  value: 'bankId',
  label: 'Banco'
}, {
  value: 'subkind',
  label: 'Subtipo'
}, {
  value: 'bank_id',
  label: 'Banco'
}, {
  value: 'tradeName',
  label: 'Nome comercial'
}, {
  value: 'trade_name',
  label: 'Nome comercial'
}, {
  value: 'document',
  label: 'Documento'
}, {
  value: 'gender',
  label: 'Gênero'
}, {
  value: 'street',
  label: 'Rua'
}, {
  value: 'district',
  label: 'Bairro'
}, {
  value: 'cityId',
  label: 'Cidade'
}, {
  value: 'city_id',
  label: 'Cidade'
}, {
  value: 'operationId',
  label: 'Operação'
}, {
  value: 'operation_id',
  label: 'Operação'
}, {
  value: 'personId',
  label: 'Pessoa'
}, {
  value: 'person_id',
  label: 'Pessoa'
}, {
  value: 'payment_method_id',
  label: 'Forma de pagamento'
}, {
  value: 'payment_condition_id',
  label: 'Condição de pagamento'
}, {
  value: 'paymentConditionId',
  label: 'Condição de pagamento'
}, {
  value: 'writeOffId',
  label: 'Operação de baixa'
}, {
  value: 'write_off_id',
  label: 'Operação de baixa'
}, {
  value: 'currentAssetId',
  label: 'Disponibilidade'
}, {
  value: 'current_asset_id',
  label: 'Disponibilidade'
}, {
  value: 'paymentMethodId',
  label: 'Forma de pagamento'
}, {
  value: 'financial_operation',
  label: 'Operação financeira'
}, {
  value: 'fiscal_tax_profile_icms.fiscal_tax_profile_icms_states.rate',
  label: "Aliquota ICMS"
}, {
  value: 'certificate_file',
  label: "Certificado Digital"
}, {
  value: 'stockProducts',
  label: "Produto"
}, {
  value: 'stock_products',
  label: "Produto"
}, {
  value: 'state_icms_expiration_day',
  label: "Vencimento do ICMS"
}, {
  value: 'accounting_entry_deadline_day',
  label: "Prazo para escrituração"
}, {
  value: 'payment',
  label: "Pagamento"
}, {
  value: 'person_bankings.number',
  label: "Número da conta"
}, {
  value: 'issuance_date',
  label: "Data de Emissão"
}, {
  value: 'departure_date',
  label: "Data de Partida"
}, {
  value: 'issuanceDate',
  label: "Data de Emissão"
}, {
  value: 'departureDate',
  label: "Data de Partida"
}, {
  value: 'stateRegistration',
  label: "Inscrição Estadual"
}, {
  value: 'state_registration',
  label: "Inscrição Estadual"
}, {
  value: 'company',
  label: "Empresa"
}, {
  value: 'operationTaxProfiles',
  label: "Perfis tributários da operação"
}, {
  value: 'TaxProfile',
  label: "Perfil tributário"
}, {
  value: 'finish',
  label: "Fim"
}, {
  value: 'start',
  label: "Início"
}, {
  value: 'rntrcCode',
  label: "Código RNTRC"
}, {
  value: 'compensationsAccounts',
  label: "Contas de Compensação"
}, {
  value: 'value',
  label: "Valor"
}, {
  value: 'configuration',
  label: "Configuração"
}, {
  value: 'rntrc_code',
  label: "Código RNTRC"
}, {
  value: 'value',
  label: "Valor"
}, {
  value: 'financial_bank',
  label: "Banco"
}, {
  value: 'branch',
  label: "Agência"
}, {
  value: 'driverId',
  label: "Motorista"
}, {
  value: 'driverId',
  label: "Motorista"
}, {
  value: 'vehicleId',
  label: "Veículo"
}, {
  value: 'ownerId',
  label: "Dono"
}, {
  value: 'cargoGrossWeight',
  label: "Peso total da carga"
}, {
  value: 'dfeModel',
  label: "Modelo"
}, {
  value: 'ncmId',
  label: "Ncm"
}, {
  value: 'fiscal_ncm_id',
  label: "Ncm"
}, {
  value: 'product',
  label: "Produto"
}, {
  value: 'value_to_pay',
  label: "Valor à pagar"
}, {
  value: 'total',
  label: "Total"
}, {
  value: 'naturalRegistration',
  label: "RG"
}, {
  value: 'natural_registration',
  label: "RG"
}, {
  value: 'cfopId',
  label: "CFOP"
}, {
  value: 'cfop_id',
  label: "CFOP"
}, {
  value: 'stateId',
  label: "Estado"
}, {
  value: 'state_id',
  label: "Estado"
}, {
  value: 'access_key',
  label: "Chave de Acesso"
}, {
  value: 'accessKey',
  label: "Chave de Acesso"
}, {
  value: 'productUnit',
  label: "Produto"
}, {
  value: 'taxProfileId',
  label: "Perfil Tributário"
}, {
  value: 'taxSituation',
  label: "Situação Tributária"
}, {
  value: 'sequenceNumber',
  label: "Sequência"
}, {
  value: 'totalTaxes',
  label: "Taxas"
}, {
  value: 'analyticAccount',
  label: "Conta Analítica"
}, {
  value: 'shipperId',
  label: "Transportador"
}, {
  value: 'fleetVehicleId',
  label: "Veículo"
}, {
  value: 'shipper_id',
  label: "Transportador"
}, {
  value: 'fleet_vehicle_id',
  label: "Veículo"
}, {
  value: 'entry_id',
  label: "Conta de partida"
}, {
  value: 'entryId',
  label: "Conta de partida"
}, {
  value: 'offset_id',
  label: "Conta de contrapartida"
}, {
  value: 'offsetId',
  label: "Conta de contrapartida"
}];