import { sanitizeBackend, sanitize } from 'grifo-components';
import { MAIN } from "../constants/addresses/AddressesFieldsOptions";
export const companyParamBuilder = (record, values) => {
  let params = sanitize(values, ["city", "contact"]);
  params.phone.naming = "main";
  params.address.naming = MAIN;
  return {
    attributes: {
      id: record ? record.id : null,
      person: {
        name: params.name,
        tradeName: params.tradeName,
        document: params.document,
        photo64: params.photo64,
        email: params.email,
        phone: params.phone,
        address: params.address,
        birth: params.birth,
        closure: params.closure
      },
      administrator: (params || {}).administratorId
    }
  };
};
export const companyDefaultBuilder = record => {
  let object = record || {};
  let person = object.person || {};
  let phone = person.phone || {};
  return {
    name: person.name,
    tradeName: person.tradeName,
    document: person.document,
    photo64: person.photo64,
    birth: sanitizeBackend(person.birth),
    closure: sanitizeBackend(person.closure),
    email: person.email,
    phone: phone,
    phoneNumber: phone.number,
    phoneAreaCode: phone.areaCode,
    phoneExtension: phone.extension,
    address: object.address,
    administratorId: object.administratorId
  };
};