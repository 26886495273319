import gql from "graphql-tag";
export const paymentConditionFragment = gql`
  fragment PaymentCondition on PaymentCondition {
    id,
    name,
    timeKind,
    time,
    installments,
    condition,
    gracePeriod,
    fees,
    discount,    
    discardedAt
  }
`;