import gql from "graphql-tag";
import { companyUserFragment } from "./CompanyUser";
export const tenantUserFragment = gql`
  fragment TenantUser on TenantUser {
    id,
    profile, 
    companyId,
    tenantId,
    personId,
    roleId,
    lastSignInAt,
    companyUsers {
      ...CompanyUser
    },
    authorizations {
      id,
      kind,
      resourceId,
    }
  }
  ${companyUserFragment} 
`;