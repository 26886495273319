import gql from "graphql-tag";
import { paymentMethodFragment } from "../fragments/PaymentMethod";
import { paymentMethodSimpleFragment } from "../fragments/PaymentMethodSimple";
export const GQL_SEARCH_PAYMENT_METHODS = gql`
  query GetByName($name: String) {    
    paymentMethods(name: $name) {
      ...PaymentMethod
    }
  }
  ${paymentMethodFragment}   
`;
export const GQL_SEARCH_BY_KIND_PAYMENT_METHODS = gql`
  query GetByKind($kind: String) {    
    paymentMethods(kind: $kind) {
      ...PaymentMethod
    }
  }
  ${paymentMethodFragment}   
`;
export const GQL_PAYMENT_METHODS_LIST = gql`
  query GetAll($pageSize: Int, $cursor: String) {    
    paymentMethodsPaginated(first: $pageSize, after: $cursor) {
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasPreviousPage
        hasNextPage
      } 
      edges { 
        cursor
        node {
          ...PaymentMethod
        }
      }
    }    
  }
  ${paymentMethodFragment}
`;
export const GQL_ALL_PAYMENT_METHODS = gql`
  {
    paymentMethods {
      ...PaymentMethodSimple
    }
  }
  ${paymentMethodSimpleFragment}   
`;
export const GQL_PAYMENT_METHOD_STORE = gql`
  mutation StorePaymentMethod($attributes: PaymentMethodAttributes!) {
    storePaymentMethod(input: {attributes: $attributes}) { 
      paymentMethod {
        ...PaymentMethod
      }, 
      errors 
    } 
  }
  ${paymentMethodFragment}   
`;
export const GQL_PAYMENT_METHODS_BY_ID = gql`
  query ById($id: ID) {
    paymentMethods(id: $id) {
      ...PaymentMethod
    }
  }
  ${paymentMethodFragment} 
`;
export const GQL_PAYMENT_METHOD_INACTIVATE = gql`
  mutation Inactivate($id: ID!) {
    inactivate(input: {id: $id, className: "Financial::PaymentMethod"}) {
      id,
      discardedAt
    }
  }
`;