import gql from "graphql-tag";
export const holidayFragment = gql`
  fragment Holiday on Holiday {
    id,
    kind, 
    name,
    day,
    month,
    date,
    recurrence,
    discardedAt,
    yearlyDate,
    facultativePoint
  }
`;