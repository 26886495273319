import gql from "graphql-tag";
import { personFragment } from "./Person";
import { addressFragment } from "./Address";
import { phoneFragment } from "./Phone";
export const companyFragment = gql`
  fragment Company on Company {
    id,
    kind,
    name,
    tradeName,
    isHeadOffice,
    isCooperative,
    person {
      ...Person
    },
    administratorId,
    address {
      ...Address
    },
    phone {
      ...Phone
    },
  }
  ${personFragment}
  ${addressFragment}
  ${phoneFragment}
`;