import gql from "graphql-tag";
import { movementSimpleFragment } from "./MovementSimple";
export const movementWithWriteOffFragment = gql`
  fragment MovementWithWriteOff on Movement {
    ...MovementSimple,
    documents64 {
      id,
      data,          
      filename,
      contentType,
      key
    },
    reversingEntryMovement{
      ...MovementSimple
    },    
    reversingMovement{
      ...MovementSimple
    }
    writeOffMovement{
      ...MovementSimple
    }
  }
  ${movementSimpleFragment}
`;