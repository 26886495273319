export const pollCommentsParamBuilder = (record, values) => {
  return {
    id: record,
    comments: values.comments
  };
};
export const pollCommentsDefaultBuilder = record => {
  return {
    title: (record || {}).title,
    description: (record || {}).description,
    duration: (record || {}).duration,
    position: (record || {}).position,
    polls: (record || {}).polls
  };
};