import gql from "graphql-tag";
import { userFragment } from "../fragments/User";
export const GQL_REGISTRATION = gql`
  mutation signUp($attributes: RegistrationAttributes!) {
    signUp(input: {attributes: $attributes}) {
      user {
        ...User          
      },
      errors
    } 
  }
  ${userFragment}
`;
export const GQL_LOGIN = gql`
  mutation UserLogin($email: String!, $password: String!) {
    userLogin(email: $email, password: $password) { 
      credentials { 
        uid,
        client,
        accessToken,
        expiry
      },
      authenticatable {
        ...User          
      }
    } 
  }
  ${userFragment}
`;
export const GQL_SET_FIREBASE = gql`
  mutation UserSetFirebase($attributes: CredentialAttributes!) {
    userSetFirebase(input: {attributes: $attributes}) { 
      message
    } 
  }
`;
export const GQL_SEND_RESET_PASSWORD = gql`
  mutation userSendPasswordReset($email: String!, $redirectUrl: String!) {
    userSendPasswordReset(email: $email, redirectUrl: $redirectUrl){
      message
    }
  }
`;
export const GQL_RESEND_CONFIRMATION = gql`
  mutation UserResendConfirmation($email: String!, $redirectUrl: String!) {
    userResendConfirmation(email: $email, redirectUrl: $redirectUrl){
      message
    }
  }
`;
export const GQL_CONFIRM = gql`
  mutation ConfirmUser( $token: String!) {
    confirmUser(input: {token: $token}){
      message,
      errors
    }
  }
`;
export const GQL_LOGOUT = gql`
  mutation UserLogout {
    userLogout { 
      authenticatable { 
        email
      }
    } 
  }
`;