import gql from "graphql-tag";
import { addressFragment, phoneFragment, companySimpleFragment, personFragment } from 'admin-front';
import { operationFragment } from "./Operation";
import { movementFragment } from "./Movement";
import { AccountPaymentFragment } from "./AccountPayment";
import { movementEntryFragment } from "./MovementEntrySimple";
export const accountFragment = gql`
  fragment Account on Account {
    id,
    transactionId,
    origin,
    kind,
    value,
    balance,
    earlyPaymentDeduction,
    earlyPaymentDeductionValue,
    latePaymentInterest,
    latePaymentInterestValue,
    latePaymentInterestKind,
    latePaymentFineValue,
    latePaymentFine,
    date,
    dueDate,
    setup,
    dateStatus,
    dueDateStatus,
    daysEllapsed,
    daysToDue,
    installmentNumber,
    totalInstallments,
    rescheduledAccount,
    reschedulingAccount,
    reschedulingAccountId,
    status,
    hasChildren,
    canEdit,
    companyId,
    company {
      ...CompanySimple
    },
    personId,
    person {
      ...Person
    },
    operationId,
    operation {
      ...Operation
    },
    entry {
      ...MovementEntrySimple     
    },
    movements {
      ...Movement
    },
    writeOffMovements {
      ...Movement
    },
    parent {
      id,
    },
    payments {
      ...AccountPayment
    }
  }
  ${companySimpleFragment}
  ${personFragment}
  ${operationFragment}  
  ${movementFragment}
  ${movementEntryFragment}  
  ${addressFragment}
  ${phoneFragment}  
  ${AccountPaymentFragment}  
`;