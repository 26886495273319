export const CONDITIONS_RULES = [{
  required: true,
  message: "Selecione a condição"
}];
export const INSTALLMENTS_RULES = [{
  required: true,
  message: "Informe a parcela"
}];
export const NAME_RULES = [{
  required: true,
  message: "Informe o nome"
}];