import { getLabelByValue } from 'grifo-components';
import { CONDITIONS_OPTIONS, TIME_KIND_OPTIONS } from "../constants/paymentConditions/PaymentConditionsFieldsOptions";
export const paymentConditionNameBuilder = form => {
  return getConditionLabel(form.getFieldValue('condition')) + getInstallmentsLabel(form.getFieldValue('installments')) + getTimeLabel(form.getFieldValue('time')) + " " + getTimeKindLabel(form.getFieldValue('time'), form.getFieldValue('timeKind')) + getGracePeriodLabel(form.getFieldValue('gracePeriod'));
};
const getConditionLabel = condition => {
  return getLabelByValue(CONDITIONS_OPTIONS, condition);
};
const getInstallmentsLabel = value => {
  if ((value || 0) === 0) return "";
  if (value > 1) return " em " + value + " vezes";
  return " em uma vez";
};
const getTimeLabel = value => {
  if (value === 0 || value == null || value === undefined) return "";
  return " à cada " + value;
};
const getTimeKindLabel = (timeValue, timeKindValue) => {
  let attribute = (timeValue || 0) > 1 ? "plural" : "label";
  return (getLabelByValue(TIME_KIND_OPTIONS, timeKindValue || '', attribute) || '').toLowerCase();
};
const getGracePeriodLabel = value => {
  if ((value || 0) === 0) return "";
  if (value > 1) return " com carência de " + value + " dias.";
  return " com carência de 1 dia.";
};