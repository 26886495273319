import { dateOrToday, getSessionInfo, sanitize } from 'grifo-components';
import { accountPaymentBuilder } from "./AccountPaymentBuilder";
export const advanceParamBuilder = (record, values) => {
  let documents64 = values.documents64 ? sanitize(values.documents64, ['name', 'url']) : null;
  return {
    attributes: {
      operationId: values.operationId,
      personId: values.personId,
      value: values.value,
      amount: values.amount,
      date: values.competencyDate.format("YYYY-MM-DD"),
      shortComplement: values.shortComplement,
      longComplement: values.longComplement,
      referencedDocument: values.referencedDocument,
      documents64: documents64,
      companyId: getSessionInfo().company.id,
      payments: values.payments
    },
    currentAssetAccountId: record.account.id
  };
};
export const advanceDefaultBuilder = record => {
  const object = record || {};
  return {
    operationId: (object.operation || {}).id,
    personId: (object.person || {}).id,
    value: object.value || 0.0,
    competencyDate: dateOrToday(object.competencyDate),
    shortComplement: object.shortComplement || "Adiantamento",
    longComplement: object.longComplement,
    referencedDocument: object.referencedDocument,
    documents64: object.documents64,
    payments: accountPaymentBuilder(object)
  };
};