import gql from "graphql-tag";
import { documentTypeFragment } from "../fragments/DocumentType";
export const GQL_DOCUMENT_TYPES_SEARCH = gql`
  {
    documentTypes {
      ...DocumentType,
    }    
  }
  ${documentTypeFragment}
`;
export const GQL_DOCUMENT_TYPES_LIST = gql`
  query GetAll($pageSize: Int, $cursor: String) {    
    documentTypesPaginated(first: $pageSize, after: $cursor) {
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasPreviousPage
        hasNextPage
      } 
      edges { 
        cursor
        node {
          ...DocumentType
        }
      }
    }    
  }
  ${documentTypeFragment}
`;
export const GQL_DOCUMENT_TYPE_INACTIVATE = gql`
  mutation Inactivate($id: ID!) {
    inactivate(input: {id: $id, className: "DocumentType"}) {
      id,
      discardedAt 
    }
}
`;
export const GQL_DOCUMENT_TYPE_STORE = gql`
  mutation StoreDocumentType($attributes: DocumentTypeAttributes!) {
    storeDocumentType(input: {attributes: $attributes}) { 
      documentType { 
        ...DocumentType,
      }, 
      errors 
    } 
  }
  ${documentTypeFragment}  
`;