export const OPENED = 'opened';
export const REOPENED = 'reopened';
export const SKIPPED = 'skipped';
export const CLOSED = 'closed';
export const AWAITING = 'awaiting';
export const INFORMATION = 'information';
export const VOTATION = 'votation';
export const PHASE_EVENTS = [{
  value: OPENED,
  label: 'Aberta'
}, {
  value: REOPENED,
  label: 'Reaberta'
}, {
  value: SKIPPED,
  label: 'Pulada'
}, {
  value: CLOSED,
  label: 'Fechada'
}, {
  value: AWAITING,
  label: 'Em recesso'
}];