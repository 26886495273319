import { sanitize, sanitizeBackend } from 'grifo-components';
import { JURIDICAL, NATURAL } from "../constants/people/PeopleFieldsOptions";
import { MAIN } from "../constants/addresses/AddressesFieldsOptions";
import { getPersonType } from "../utils/PersonUtil";
export const personParamBuilder = (record, values) => {
  return {
    attributes: personAtributesBuilder(record, values)
  };
};
export const personAtributesBuilder = (record, values) => {
  let params = sanitize(values, ["city", "contact", "state"]);
  let type = getPersonType(params);
  let gender = type === NATURAL ? params.gender : JURIDICAL;
  return {
    id: record ? record.id : null,
    name: params.name,
    tradeName: params.tradeName,
    document: params.document,
    naturalRegistration: params.naturalRegistration,
    naturalRegistrationAgencyId: params.naturalRegistrationAgencyId,
    naturalRegistrationStateId: params.naturalRegistrationStateId,
    jobRoleId: params.jobRoleId,
    photo64: params.photo64,
    email: params.email,
    gender: gender,
    birth: params.birth,
    cadunicoCode: params.cadunicoCode,
    nisCode: params.nisCode,
    closure: params.closure,
    phones: params.phones,
    addresses: params.addresses,
    contacts: params.contacts,
    rntrcCode: params.rntrcCode,
    nickname: params.nickname,
    pisCode: params.pisCode,
    ctps: params.ctps,
    voterRegistrationNumber: params.voterRegistrationNumber,
    voterRegistrationZone: params.voterRegistrationZone,
    voterRegistrationSection: params.voterRegistrationSection,
    nationalityId: params.nationalityId,
    citizenshipId: params.citizenshipId,
    inss: params.inss,
    incomeId: params.incomeId,
    lowIncome: params.lowIncome,
    dependents: params.dependents,
    occupationCode: params.occupationCode,
    personDocuments: (params.personDocuments || []).map(doc => {
      return {
        ...doc,
        documents64: doc.documents64.map(docs64 => {
          delete docs64.name;
          delete docs64.url;
          return {
            ...docs64
          };
        })
      };
    })
  };
};
export const personDefaultBuilder = record => {
  const object = record || {};
  const birth = object.birth;
  const closure = object.closure;
  return {
    name: object.name,
    tradeName: object.tradeName,
    document: object.document,
    naturalRegistration: object.naturalRegistration,
    naturalRegistrationAgencyId: object.naturalRegistrationAgencyId,
    naturalRegistrationStateId: object.naturalRegistrationStateId,
    jobRoleId: object.jobRoleId,
    photo64: object.photo64,
    email: object.email,
    cadunicoCode: object.cadunicoCode,
    nisCode: object.nisCode,
    birth: sanitizeBackend(birth),
    closure: sanitizeBackend(closure),
    gender: object.gender,
    phone: object.phone,
    phones: object.phones,
    nickname: object.nickname,
    pisCode: object.pisCode,
    ctps: object.ctps,
    voterRegistrationNumber: object.voterRegistrationNumber,
    voterRegistrationZone: object.voterRegistrationZone,
    voterRegistrationSection: object.voterRegistrationSection,
    nationalityId: object.nationalityId,
    citizenshipId: object.citizenshipId,
    inss: object.inss,
    incomeId: object.incomeId,
    lowIncome: object.lowIncome,
    dependents: object.dependents,
    occupationCode: object.occupationCode,
    addresses: object.addresses || [{
      naming: MAIN
    }],
    address: object.address,
    contacts: object.contacts,
    personDocuments: (object.personDocuments || []).map(doc => ({
      ...doc,
      issueDate: sanitizeBackend(doc.issueDate),
      documents64: ((doc || {}).documents64 || []).map(file => ({
        name: file.filename,
        status: 'done',
        uid: file.id,
        key: file.key,
        url: file.key,
        filename: file.filename,
        contentType: file.contentType,
        data: file.data
      }))
    })),
    rntrcCode: object.rntrcCode
  };
};