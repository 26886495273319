import gql from "graphql-tag";
import { jobRoleFragment } from "../fragments/JobRole";
export const GQL_JOB_ROLE_LIST = gql`
  query GetAll($pageSize: Int, $cursor: String, $search: String) {    
    jobRolesPaginated(first: $pageSize, after: $cursor, search: $search) {
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasPreviousPage
        hasNextPage
      } 
      edges { 
        cursor
        node {
          ...JobRole
        }
      }
    }    
  }
  ${jobRoleFragment}
`;
export const GQL_JOB_ROLE_INACTIVATE = gql`
  mutation Inactivate($id: ID!) {
    inactivate(input: {id: $id, className: "JobRole"}) {
      id,
      discardedAt 
    }
  }
`;
export const GQL_JOB_ROLE_STORE = gql`
  mutation StoreJobRole($attributes: JobRoleAttributes!) {
    storeJobRole(input: {attributes: $attributes}) { 
      jobRole { 
        ...JobRole,
      }, 
      errors 
    } 
  }
  ${jobRoleFragment}  
`;
export const GQL_JOB_ROLE_SEARCH = gql`
  {
    jobRoles {
      ...JobRole,
    }    
  }
  ${jobRoleFragment}
`;