import gql from "graphql-tag";
import { electionVotesFragment } from "./ElectionVotes";
import { slateMemberFragment } from "./SlateMember";
export const slateFragment = gql`
  fragment Slate on Slate  { 
    id,
    name,
    councilType,
    discardedAt,
    description,
    canChange,
    documents64 {
      id,
      data,
      filename,
      contentType,
      key
    },
    slateMembers {
      ...SlateMember
    },
    electionVotes {
      ...ElectionVotes
    }
  }
  ${slateMemberFragment}
  ${electionVotesFragment}
`;