export const PHONES_NAMING_OPTIONS = [{
  value: 'main',
  label: 'Principal'
}, {
  value: 'home',
  label: 'Casa'
}, {
  value: 'work',
  label: 'Trabalho'
}, {
  value: 'other',
  label: 'Outro'
}];