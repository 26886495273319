import gql from "graphql-tag";
export const personToCardFragment = gql`
fragment PersonToCard on Person {
  document
  name
  tradeName
  email
  phone {
    areaCode
    number
    extension
  }
  mainAddress {
    street
    number
    room
    complement
    city{
      cityState
    }
    zipcode
  }
}
`;