export const bankSlipParamsBuilder = (record, values) => {
  return {
    attributes: {
      id: record ? record.id : null,
      bankAccountId: values.bankAccountId,
      instruction1: values.instruction1,
      instruction2: values.instruction2,
      instruction3: values.instruction3,
      instruction4: values.instruction4,
      instruction5: values.instruction5,
      ourNumber: values.ourNumber,
      agreementId: values.agreementId
    }
  };
};
export const bankSlipDefaultValuesBuilder = record => {
  return {
    id: record?.id
  };
};