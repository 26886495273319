import gql from "graphql-tag";
import { documentFolderFragment } from "./DocumentFolder";
export const documentFolderKindFragment = gql`
  fragment DocumentFolderKind on DocumentFolderKind {
    id,
    required,
    documentFolderId,
    documentTypeId,
    documentFolder {
      ...DocumentFolder
    }
  }
  ${documentFolderFragment}
`;