import gql from "graphql-tag";
import { chargeFragment } from "../fragments/Charge";
export const GQL_CHARGES_LIST = gql`
  {
    charges {
      ...Charge
    }
  }
  ${chargeFragment}   
`;
export const GQL_CHARGES_BY_ACCOUNT_ID = gql`
  query GetByAccountId($accountId: String) {
    charges(accountId: $accountId) { 
      ...Charge
    }
  }
  ${chargeFragment}   
`;
export const GQL_CHARGE_STORE = gql`
  mutation StoreCharge($attributes: ChargeAttributes!) {
    storeCharge(input: {attributes: $attributes}) { 
      charge {
        ...Charge
    }, 
      errors 
    } 
  }
  ${chargeFragment}   
`;