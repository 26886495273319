import gql from "graphql-tag";
import { slateFragment } from "./Slate";
export const votingFragment = gql`
  fragment Voting on Voting  { 
    id,
    choice,
    slate {
      ...Slate
    }
}
${slateFragment}

`;