import gql from "graphql-tag";
import { paymentConditionFragment } from "../fragments/PaymentCondition";
export const GQL_SEARCH_PAYMENT_CONDITIONS = gql`
  query GetByName($name: String) {    
    paymentConditions(name: $name) {
      ...PaymentCondition
    }
  }
  ${paymentConditionFragment}   
`;
export const GQL_PAYMENT_CONDITIONS_LIST = gql`
  query GetAll($pageSize: Int, $cursor: String) {    
    paymentConditionsPaginated(first: $pageSize, after: $cursor) {
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasPreviousPage
        hasNextPage
      } 
      edges { 
        cursor
        node {
          ...PaymentCondition
        }
      }
    }    
  }
  ${paymentConditionFragment}
`;
export const GQL_ALL_PAYMENT_CONDITIONS = gql`
  {
    paymentConditions {
      ...PaymentCondition
    }
  }
  ${paymentConditionFragment}   
`;
export const GQL_PAYMENT_CONDITIONS_BY_METHOD = gql`
  query GetByMethod($method: ID) {    
    paymentConditions(byPaymentMethod: $method) { 
      ...PaymentCondition
    }
  }
  ${paymentConditionFragment}
`;
export const GQL_PAYMENT_CONDITIONS_BY_ID = gql`
query ById($id: ID) {    
  paymentConditions(id: $id) {
    ...PaymentCondition
  }
}
${paymentConditionFragment}   
`;
export const GQL_PAYMENT_CONDITION_STORE = gql`
  mutation StorePaymentCondition($attributes: PaymentConditionAttributes!) {
    storePaymentCondition(input: {attributes: $attributes}) { 
      paymentCondition {
        ...PaymentCondition
      }, 
      errors 
    } 
  }
  ${paymentConditionFragment}   
`;
export const GQL_PAYMENT_CONDITION_INACTIVATE = gql`
  mutation Inactivate($id: ID!) {
    inactivate(input: {id: $id, className: "Financial::PaymentCondition"}) {
      id,
      discardedAt
    }
  }
`;