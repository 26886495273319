export const CASHIER = 'cashier';
export const TREASURY = 'treasury';
export const CREDIT_CARD_COMPANY = 'credit_card_company';
export const BANK = 'bank';
export const OPENING = 'opening';
export const CLOSURE = 'closure';
export const DEBIT = 'debit';
export const CREDIT = 'credit';
export const OPENED = 'opened';
export const CLOSED = 'closed';
export const AVAIlABLE = 'available';
export const SELLING = 'selling';
export const CURRENT_ASSETS_KIND_OPTIONS = [{
  value: CASHIER,
  label: 'Caixa'
}, {
  value: BANK,
  label: 'Banco'
}, {
  value: TREASURY,
  label: 'Tesouraria'
}, {
  value: CREDIT_CARD_COMPANY,
  label: 'Operadora de Cartão de Crédito'
}];
export const CURRENT_ASSETS_OPTIONS = [{
  value: CASHIER,
  label: 'Caixa'
}, {
  value: TREASURY,
  label: 'Tesouraria'
}, {
  value: CREDIT_CARD_COMPANY,
  label: 'Operadora de Cartão de Crédito'
}];
export const CURRENT_ASSETS_OPTIONS_FROM_BANK = [{
  value: BANK,
  label: 'Banco'
}, {
  value: CREDIT_CARD_COMPANY,
  label: 'Operadora de Cartão de Crédito'
}];
export const CURRENT_ASSETS_STATUS = [{
  value: OPENED,
  label: 'CAIXA ABERTO'
}, {
  value: CLOSED,
  label: 'CAIXA FECHADO'
}, {
  value: AVAIlABLE,
  label: 'CAIXA DISPONÍVEL'
}, {
  value: SELLING,
  label: 'EM VENDA'
}];