import gql from "graphql-tag";
import { personFragment } from 'admin-front';
export const participantFragment = gql`
  fragment Participant on Participant  { 
    id,
    role,
    canVote,
    voteOfMinerva,
    personId,
    person {
      ...Person
    }
  }
  ${personFragment}
`;