import { today, nextDayFromDay, nextWeekFromDay, nextMonthFromDay, nextYearFromDay, dateOrToday } from 'grifo-components';
import { accountPaymentBuilder } from './AccountPaymentBuilder';
const payment = record => {
  let date = today();
  if (record.timeKind == 'day') {
    date = nextDayFromDay(record.day);
  } else if (record.timeKind == 'week') {
    date = nextWeekFromDay(record.day);
  } else if (record.timeKind == 'month') {
    date = nextMonthFromDay(record.day);
  } else if (record.timeKind == 'year') {
    date = nextYearFromDay(record.day);
  }
  let data = [{
    paymentCondition: record.paymentCondition,
    paymentConditionId: record.paymentConditionId,
    paymentMethod: record.paymentMethod,
    paymentMethodId: record.paymentMethodId,
    date: date,
    time: 1,
    timeKind: record.timeKind,
    total: record.value,
    valueToPay: record.value,
    installment: 1,
    methodFees: 0,
    methodFeesRate: 0,
    conditionFees: 0,
    conditionFeesRate: 0,
    fees: 0,
    methodDiscount: 0,
    methodDiscountRate: 0,
    conditionDiscount: 0,
    conditionDiscountRate: 0,
    discount: 0,
    total: record.value,
    amount: 0,
    gracePeriod: 0,
    gracePeriod: 0
  }];
  return data;
};
export const accountFromRecurrenceDefaultBuilder = record => {
  const object = record || {};
  object.payments = payment(object);
  return {
    operationId: (object.operation || {}).id,
    personId: (object.person || {}).id,
    value: object.value || 0.0,
    amount: object.value || 0.0,
    competencyDate: dateOrToday(object.competencyDate),
    dueDate: object.day,
    shortComplement: object.description,
    payments: accountPaymentBuilder(object),
    valueToPay: object.value || 0.0
  };
};