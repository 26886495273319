export const DAILY = 'daily';
export const MONTHLY = 'monthly';
export const TO_PAY = 'to_pay';
export const TO_RECEIVE = 'to_receive';
export const TAXES_TO_RECOVERY = 'taxes_to_recovery';
export const TAXES_TO_COLLECT = 'taxes_to_collect';
export const TRANSFERS = 'transfers';
export const ADDITION = 'addition';
export const DEDUCTION = 'deduction';
export const WRITE_OFF = 'write_off';
export const TO_PAY_IN_ADVANCE = 'to_pay_in_advance';
export const TO_RECEIVE_IN_ADVANCE = 'to_receive_in_advance';
export const CAPITAL_PAYMENT = 'capital_payment';
export const OF_CURRENT_ASSET = 'of_current_asset';
export const CURRENT_ASSET_ADDITION = 'current_asset_addition';
export const CURRENT_ASSET_DEDUCTION = 'current_asset_deduction';
export const PROTEST_RUNNING_DAYS = 'protest_running_days';
export const DO_NOT_PROTEST = 'do_not_protest';
export const CANCEL_PROTEST_INSTRUCTION = 'cancel_protest_instruction';
export const OPERATIONS_INTEREST_FREQUENCY = [{
  value: DAILY,
  label: 'Dia'
}, {
  value: MONTHLY,
  label: 'Mês'
}];
export const OPERATIONS_KIND = [{
  value: TO_PAY,
  label: 'À Pagar'
}, {
  value: TO_RECEIVE,
  label: 'À Receber'
}, {
  value: TAXES_TO_RECOVERY,
  label: 'Tributo à recuperar'
}, {
  value: TAXES_TO_COLLECT,
  label: 'Tributo à recolher'
}, {
  value: TRANSFERS,
  label: 'Transferência'
}, {
  value: ADDITION,
  label: 'Acréscimo'
}, {
  value: DEDUCTION,
  label: 'Desconto'
}, {
  value: WRITE_OFF,
  label: 'Baixa'
}, {
  value: TO_PAY_IN_ADVANCE,
  label: 'Adiantamento à pagar'
}, {
  value: TO_RECEIVE_IN_ADVANCE,
  label: 'Adiantamento à receber'
}, {
  value: CAPITAL_PAYMENT,
  label: 'Capital a integralizar'
}, {
  value: OF_CURRENT_ASSET,
  label: 'Para disponibilidades'
}];
export const PROTEST_CODE_OPTIONS = [{
  value: PROTEST_RUNNING_DAYS,
  label: 'Protestar dias corridos'
}, {
  value: DO_NOT_PROTEST,
  label: 'Não Protestar'
}, {
  value: CANCEL_PROTEST_INSTRUCTION,
  label: 'Cancelar Instrução de Protesto',
  disabled: true
}];
export const OPERATIONS_LIST_KIND = [{
  value: TO_PAY,
  label: 'À Pagar'
}, {
  value: TO_RECEIVE,
  label: 'À Receber'
}, {
  value: TAXES_TO_RECOVERY,
  label: 'Tributo à recuperar'
}, {
  value: TAXES_TO_COLLECT,
  label: 'Tributo à recolher'
}, {
  value: TRANSFERS,
  label: 'Transferência'
}, {
  value: ADDITION,
  label: 'Acréscimo'
}, {
  value: DEDUCTION,
  label: 'Desconto'
}, {
  value: WRITE_OFF,
  label: 'Baixa'
}, {
  value: TO_PAY_IN_ADVANCE,
  label: 'Adiantamento à pagar'
}, {
  value: TO_RECEIVE_IN_ADVANCE,
  label: 'Adiantamento à receber'
}, {
  value: CAPITAL_PAYMENT,
  label: 'Capital a integralizar'
}, {
  value: OF_CURRENT_ASSET,
  label: 'Para disponibilidades'
}, {
  value: CURRENT_ASSET_ADDITION,
  label: 'Entrada para disponibilidades'
}, {
  value: CURRENT_ASSET_DEDUCTION,
  label: 'Saída para disponibilidades'
}];