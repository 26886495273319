import gql from "graphql-tag";
export const personStatisticFragment = gql`
  fragment PersonStatistic on PersonStatistic {
    id,
    personId,
    kind
    value,
    balance,    
    paid,
  }
`;