import gql from "graphql-tag";
import { resourceFragment, resourceListFragment } from "../fragments/Resource";
export const GQL_RESOURCES_SELECT = gql`
  query GetToConfigure($toConfigure: Boolean) {    
    resources(toConfigure: $toConfigure) {
      ...ResourceList
    }    
  }
  ${resourceListFragment}
`;
export const GQL_RESOURCE_LIST = gql`
  query GetAll($pageSize: Int, $cursor: String, $search: String) {    
    resourcesPaginated(first: $pageSize, after: $cursor, search: $search) {
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasPreviousPage
        hasNextPage
      } 
      edges { 
        cursor
        node {
          ...ResourceList
        }
      }
    }    
  }
  ${resourceListFragment}
`;
export const GQL_RESOURCE_STORE = gql`
  mutation StoreResource($attributes: ResourceAttributes!) {
    storeResource(input: {attributes: $attributes}) { 
      resource { 
        ...Resource,
      }, 
      errors 
    } 
  }
  ${resourceFragment}  
`;