import gql from "graphql-tag";
import { memberRegistrationFragment } from "../fragments/MemberRegistration";
export const GQL_SEARCH_MEMBER_REGISTRATIONS = gql`
  query GetByRegistrationCode($registrationCode: String) {    
    memberRegistrations(registrationCode: $registrationCode) { 
        ...MemberRegistration
    }
  }
  ${memberRegistrationFragment}   
`;
export const GQL_MEMBER_REGISTRATIONS_BY_PERSON = gql`
  query GetByPerson($pageSize: Int, $cursor: String, $personId: ID) {    
    memberRegistrationsPaginated(first: $pageSize, after: $cursor, personId: $personId) {
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasPreviousPage
        hasNextPage
      } 
      edges { 
        cursor
        node {
          ...MemberRegistration
        }
      }
    }    
  }
  ${memberRegistrationFragment}
`;
export const GQL_MEMBER_REGISTRATIONS_LIST = gql`
  query GetAll($pageSize: Int, $cursor: String, $memberRegistrationFilter: MemberRegistrationFilter) {    
    memberRegistrationsPaginated(first: $pageSize, after: $cursor, memberRegistrationFilter: $memberRegistrationFilter) {
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasPreviousPage
        hasNextPage
      } 
      edges { 
        cursor
        node {
          ...MemberRegistration
        }
      }
    }    
  }
  ${memberRegistrationFragment}
`;
export const GQL_MEMBERS_TO_DISTRIBUTION = gql`
  query GetByYear($year: ID) {
    memberRegistrations(year: $year) {
      ...MemberRegistration
    }
  }
  ${memberRegistrationFragment}  
`;
export const GQL_MEMBER_REGISTRATION_STORE = gql`
  mutation StoreMemberRegistration($attributes: MemberRegistrationAttributes!) {
    storeMemberRegistration(input: {attributes: $attributes}) { 
      memberRegistration {
        ...MemberRegistration
    }, 
      errors 
    } 
  }
  ${memberRegistrationFragment}   
`;