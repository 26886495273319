export const ORDINARY = 'ordinary';
export const EXTRAORDINARY = 'extraordinary';
export const MEETING = 'meeting';
export const FISCAL_COUNCIL_MEETING = 'fiscal_council_meeting';
export const ADMINISTRATIVE_COUNCIL_MEETING = 'administrative_council_meeting';
export const AGO = 'ago';
export const AGE = 'age';
export const KIND = [{
  value: ORDINARY,
  label: 'Ordinária'
}, {
  value: EXTRAORDINARY,
  label: 'Extraordinária'
}, {
  value: MEETING,
  label: 'Reunião'
}, {
  value: FISCAL_COUNCIL_MEETING,
  label: 'Reunião do conselho fiscal'
}, {
  value: ADMINISTRATIVE_COUNCIL_MEETING,
  label: 'Reunião do conselho administrativo'
}, {
  value: AGO,
  label: 'AGO'
}, {
  value: AGE,
  label: 'AGE'
}];