import { deepGet, getLabelByValue } from './ListUtil';
import { onlyAttributeName } from './StringUtil';
import { CUSTOM_TRANSLATE } from './Translate';
export const bindServerValidations = (form, formValidations, serverValidations) => {
  serverValidations = JSON.parse(serverValidations);
  const fieldValidations = [];
  const objectValidations = [];
  for (const serverValidation in serverValidations) {
    var field = onlyAttributeName(serverValidation);
    let message = serverValidations[serverValidation];
    message = Array.isArray(message) ? message : [message];
    if (form.getFieldsValue()[field]) fieldValidations.push({
      name: field,
      errors: message
    });
    objectValidations.push({
      name: translate(serverValidation),
      errors: message
    });
  }
  formValidations(objectValidations);
  form.setFields(fieldValidations);
};
export const bindFormValidations = (info, formValidations) => {
  const objectValidations = [];
  const errorFields = info.errorFields;
  for (const i in errorFields) {
    var field = translate(onlyAttributeName(errorFields[i].name.join('.')));
    let message = errorFields[i].errors;
    objectValidations.push({
      name: field,
      errors: message
    });
  }
  formValidations(objectValidations);
};
const translate = field => {
  let translation = getLabelByValue(CUSTOM_TRANSLATE, field);
  console.log(field);
  return translation || onlyAttributeName(field);
};
export const plainServerValidations = serverValidations => {
  serverValidations = JSON.parse(serverValidations);
  let validations = '';
  for (const serverValidation in serverValidations) {
    var field = onlyAttributeName(serverValidation);
    let message = serverValidations[serverValidation];
    message = Array.isArray(message) ? message : [message];
    validations += field + ': ' + message + ' ';
  }
  return validations;
};
export const bind = (form, value, fieldname, fieldList = undefined) => {
  if (!form) return;
  if (Array.isArray(value) && typeof value[0] === 'object') bindFields(form, value, fieldname, fieldList);else form.setFields([{
    name: mountFieldPath(fieldname, fieldList),
    value: value
  }]);
};
export const bindFields = (form, values, fieldname = undefined, fieldList = undefined) => {
  if (!form) return;
  let fieldPath = fieldname;
  let fields = values.map(element => {
    let [key] = Object.keys(element);
    let [value] = Object.values(element);
    if (fieldname == undefined) {
      return {
        name: key,
        value: value
      };
    }
    if (Array.isArray(fieldPath)) fieldPath = fieldname.concat([key]);else fieldPath = [fieldname, key];
    return {
      name: mountFieldPath(fieldPath, fieldList),
      value: value
    };
  });
  form.setFields(fields);
};
export const getValueFromForm = (form, fieldname, fieldList = undefined) => {
  const fields = form.getFieldsValue();
  return deepGet(fields, mountFieldPath(fieldname, fieldList));
};
export const mountFieldPath = (fieldname, fieldList = undefined) => {
  let fieldPath = [];
  if (Array.isArray(fieldname)) fieldPath = fieldname.slice();else fieldPath = [fieldname];
  if (fieldList) fieldPath.unshift(fieldList);
  return fieldPath;
};