import { today, sanitize, getSessionInfo } from 'grifo-components';
import { accountPaymentBuilder } from "./AccountPaymentBuilder";
export const reschedulingParamBuilder = (record, values) => {
  let documents64 = values.documents64 ? sanitize(values.documents64, ['name', 'url']) : null;
  return {
    attributes: {
      operationId: values.operationId,
      personId: values.personId,
      value: values.value,
      amount: values.amount,
      date: values.competencyDate.format("YYYY-MM-DD"),
      shortComplement: values.shortComplement,
      longComplement: values.longComplement,
      referencedDocument: values.referencedDocument,
      documents64: documents64,
      companyId: getSessionInfo().company.id,
      payments: values.payments
    },
    accountId: record.id
  };
};
export const reschedulingDefaultBuilder = record => {
  const object = record || {};
  let payments = {
    payments: [{
      valueToPay: record?.balance
    }]
  };
  return {
    operationId: (object.operation || {}).id,
    personId: (object.person || {}).id,
    value: object.balance || 0.0,
    amount: object.balance || 0.0,
    competencyDate: today(),
    shortComplement: "Reparcelamento de conta",
    longComplement: object.longComplement,
    referencedDocument: object.referencedDocument,
    documents64: object.documents64,
    payments: accountPaymentBuilder(payments, true)
  };
};