import gql from "graphql-tag";
import { accountSimpleFragment } from "./AccountSimple";
export const movementSimpleFragment = gql`
  fragment MovementSimple on Movement {
    id,
    transactionId,      
    operationId,      
    accountId,      
    shortComplement,
    longComplement,
    referencedDocument,
    kind,        
    reversingEntry,
    createdAt,          
    usedAsCompensation,
    hasSourceCompensation,
    currentAssetName,
    reversed,
    value,
    date, 
    setup,
    account {
      ...AccountSimple
    }
  }
  ${accountSimpleFragment}
`;