import gql from "graphql-tag";
import { roleFragment, roleLoadFragment } from "../fragments/Role";
export const GQL_ROLES_SELECT = gql`
  {
    roles {
      ...Role,
    }    
  }
  ${roleFragment}
`;
export const GQL_ROLE_LIST = gql`
  query GetAll($pageSize: Int, $cursor: String, $search: String) {    
    rolesPaginated(first: $pageSize, after: $cursor, search: $search) {
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasPreviousPage
        hasNextPage
      } 
      edges { 
        cursor
        node {
          ...Role
        }
      }
    }    
  }
  ${roleFragment}
`;
export const GQL_ROLE_LOAD = gql`
  query GetById($id: ID) {    
    roles(id: $id) {
      ...RoleLoad
    }
  }
  ${roleLoadFragment}
`;
export const GQL_ROLE_STORE = gql`
  mutation StoreRole($attributes: RoleAttributes!) {
    storeRole(input: {attributes: $attributes}) { 
      role { 
        ...Role,
      }, 
      errors 
    } 
  }
  ${roleFragment}  
`;
export const GQL_ROLES_BY_TENANT = gql`
  query GetByTenantId($tenantId: ID) {    
    roles(tenantId: $tenantId) {
      ...Role
    }
  }
  ${roleFragment}
`;