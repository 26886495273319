import gql from "graphql-tag";
import { paymentConditionFragment } from "./PaymentCondition";
export const paymentMethodConditionFragment = gql`
  fragment PaymentMethodCondition on PaymentMethodCondition {
    id,
    paymentConditionId,
    paymentCondition {
        ...PaymentCondition
      }
    fees,
    discount
    name
  }
  ${paymentConditionFragment}
`;