export const resultCenterParamBuilder = (record, values) => {
  return {
    attributes: {
      id: record ? record.id : null,
      description: values.description,
      name: values.name,
      parentId: values.parentId
    }
  };
};
export const resultCenterDefaultBuilder = record => {
  return {
    description: (record || {}).description,
    name: (record || {}).name,
    parentId: ((record || {}).parent || {}).id
  };
};