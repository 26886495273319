import gql from "graphql-tag";
import { accountSimpleFragment } from "./AccountSimple";
import { operationFragment } from "./Operation";
import { currentAssetOpeningFragment } from "./CurrentAssetOpening";
export const currentAssetFragment = gql`
  fragment CurrentAsset on CurrentAsset  { 
    id,
    name,
    kind,
    implantationDate,
    controlsOpening,
    opened,
    userId,
    discardedAt,
    operationId,
    operation {
      ...Operation      
    }
    account {
      ...AccountSimple
    }
    currentAssetOpenings {
      ...CurrentAssetOpening
    }
    currentAssetActualOpening {
      ...CurrentAssetOpening
    }
  }
  ${operationFragment}
  ${accountSimpleFragment}
  ${currentAssetOpeningFragment}
`;