import { TO_PAY, TO_RECEIVE, TAXES_TO_RECOVERY, TAXES_TO_COLLECT, ADDITION, DEDUCTION, WRITE_OFF, TO_PAY_IN_ADVANCE, TO_RECEIVE_IN_ADVANCE, OF_CURRENT_ASSET } from "../operations/OperationsFieldsOptions";
export const REVERSING_ENTRY = 'reversing_entry';
export const TRANSFER_SOURCE = 'transfer_source';
export const TRANSFER_TARGET = 'transfer_target';
export const MOVEMENTS_KIND_SIMPLE = [{
  value: TO_PAY,
  label: 'À Pagar'
}, {
  value: TO_RECEIVE,
  label: 'À Receber'
}];
export const MOVEMENTS_KIND = [{
  value: TO_PAY,
  label: 'À Pagar'
}, {
  value: TO_RECEIVE,
  label: 'À Receber'
}, {
  value: TAXES_TO_RECOVERY,
  label: 'Tributo à recuperar'
}, {
  value: TAXES_TO_COLLECT,
  label: 'Tributo à recolher'
}, {
  value: ADDITION,
  label: 'Acréscimo'
}, {
  value: DEDUCTION,
  label: 'Desconto'
}, {
  value: WRITE_OFF,
  label: 'Baixa'
}, {
  value: TO_PAY_IN_ADVANCE,
  label: 'Adiantamento à pagar'
}, {
  value: TO_RECEIVE_IN_ADVANCE,
  label: 'Adiantamento à receber'
}, {
  value: OF_CURRENT_ASSET,
  label: 'Para disponibilidades'
}, {
  value: REVERSING_ENTRY,
  label: 'Estorno'
}, {
  value: TRANSFER_SOURCE,
  label: 'Origem da transferência'
}, {
  value: TRANSFER_TARGET,
  label: 'Destino da transferência'
}];