import gql from "graphql-tag";
export const GQL_AGREE_POLL = gql`
  mutation AgreePoll($id: ID!, $comments: String) { 
    agreePoll(input: {id: $id, comments: $comments}) {
      id,
      errors
    }
  }
`;
export const GQL_DISAGREE_POLL = gql`
  mutation DisagreePoll($id: ID!, $comments: String) { 
    disagreePoll(input: {id: $id, comments: $comments}) {
      id,
      errors
    }
  }
`;
export const GQL_ABSTAIN_POLL = gql`
  mutation AbstainPoll($id: ID!, $comments: String) { 
    abstainPoll(input: {id: $id, comments: $comments}) {
      id,
      errors
    }
  }
`;