import { getSessionInfo, dateOrToday } from 'grifo-components';
import { CASHIER } from "../constants/currentAssets/CurrentAssetsFieldsOptions";
export const currentAssetParamBuilder = (record, values) => {
  let controlsOpening = values?.controlsOpening || false;
  return {
    attributes: {
      id: record ? record.id : null,
      companyId: getSessionInfo().company.id,
      operationId: values.operationId,
      name: values.name,
      kind: values.kind,
      implantationDate: values.implantationDate,
      controlsOpening: controlsOpening
    }
  };
};
export const currentAssetDefaultBuilder = record => {
  let controlsOpening = record?.controlsOpening || false;
  return {
    name: record?.name,
    kind: record?.kind || CASHIER,
    implantationDate: dateOrToday(record?.implantationDate),
    operationId: record?.operation?.id,
    controlsOpening: controlsOpening
  };
};