export const PRIMARY = 'primary';
export const REVERSED = 'reversed';
export const ERASED = 'erased';
export const STATUS_KIND = [{
  value: PRIMARY,
  label: 'Normal'
}, {
  value: REVERSED,
  label: 'Estorno'
}, {
  value: ERASED,
  label: 'Excluído'
}];