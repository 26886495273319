export const pollTotalVoteParamBuilder = (record, values) => {
  return {
    id: (record || {}).id,
    offlineAgree: values.offlineAgree,
    offlineDisagree: values.offlineDisagree,
    offlineAbstain: values.offlineAbstain,
    minutes: values.minutes
  };
};
export const pollTotalVoteDefaultBuilder = record => {
  return {
    title: (record || {}).title,
    description: (record || {}).description,
    duration: (record || {}).duration
  };
};