import gql from "graphql-tag";
import { electionFragment } from "../fragments/Election";
export const GQL_ELECTIONS_LIST = gql`
  query GetAll($pageSize: Int, $cursor: String) {    
    electionsPaginated(first: $pageSize, after: $cursor) {
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasPreviousPage
        hasNextPage
      } 
      edges { 
        cursor
        node {
          ...Election
        }
      }
    }    
  }
  ${electionFragment}
`;
export const GQL_ELECTION_INACTIVATE = gql`
  mutation Inactivate($id: ID!) { 
    inactivate(input: {id: $id, className: "Cooperative::Election"}) {
      id,
      discardedAt
    }
  }
`;
export const GQL_ELECTION_STORE = gql`
  mutation StoreElection($attributes: ElectionAttributes!) {
    storeElection(input: {attributes: $attributes}) { 
      election {
        ...Election
    }, 
      errors 
    } 
  }
  ${electionFragment}
`;