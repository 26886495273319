import gql from "graphql-tag";
export const GQL_ZIPCODE_LIST = gql`
  query GetByZipcode($zipcode: String) {    
    zipcode(zipcode: $zipcode) {
        zipcode,
        street,
        complement,
        district,
        cityId,
        city {
            id
            name
            state {
                name
                initials
            }
        }
    }
  }
`;