import gql from "graphql-tag";
import { movementSimpleFragment } from "./MovementSimple";
export const movementFragment = gql`
  fragment Movement on Movement {
    ...MovementSimple,
    documents64 {
      id,
      data,          
      filename,
      contentType,
      key
    },
    reversingEntryMovement{
      ...MovementSimple
    },    
    reversingMovement{
      ...MovementSimple
    }    
  }
  ${movementSimpleFragment}
`;