import { ADMIN, CONFIG, ADVANCED_CONFIG } from 'grifo-components';
export const buildFeatures = () => {
  const adminPath = "/" + ADMIN;
  const adminConfigPath = adminPath + '/' + CONFIG;
  const adminAdvancedConfigPath = adminConfigPath + '/' + ADVANCED_CONFIG;
  const adminAdvancedConfigRoutes = [{
    ordination: '01.04.08.01',
    name: 'Recursos do sistema',
    key: adminAdvancedConfigPath + "/resources",
    routePath: adminAdvancedConfigPath + "/resources",
    kind: 'page',
    status: 'active',
    authorization: 'super_admin',
    content: {
      page: "ResourcesPage",
      import_path: "containers/resources/ResourcesPage"
    }
  }, {
    ordination: '01.04.08.02',
    name: 'Usuários (todos)',
    key: adminAdvancedConfigPath + "/users",
    routePath: adminAdvancedConfigPath + "/users",
    kind: 'page',
    status: 'active',
    authorization: 'super_admin',
    content: {
      page: "AllUsersPage",
      import_path: "containers/allUsers/AllUsersPage"
    }
  }, {
    ordination: '01.04.08.03',
    name: 'Workspaces',
    key: adminAdvancedConfigPath + "/tenants",
    routePath: adminAdvancedConfigPath + "/tenants",
    kind: 'page',
    status: 'active',
    authorization: 'super_admin',
    content: {
      page: "TenantsPage",
      import_path: "containers/tenants/TenantsPage"
    }
  }, {
    ordination: '01.04.08.04',
    name: 'Órgãos Expedidores',
    key: adminAdvancedConfigPath + "/registration_agencies",
    routePath: adminAdvancedConfigPath + "/registration_agencies",
    kind: 'page',
    status: 'active',
    authorization: 'super_admin',
    content: {
      page: "RegistrationAgenciesPage",
      import_path: "containers/registrationAgencies/RegistrationAgenciesPage"
    }
  }];
  const adminConfigRoutes = [{
    ordination: '01.04.01',
    name: 'Tipos de Documento',
    key: adminConfigPath + "/document_types",
    routePath: adminConfigPath + "/document_types",
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "DocumentTypesPage",
      import_path: "containers/documentTypes/DocumentTypesPage"
    }
  }, {
    ordination: '01.04.02',
    name: 'Pastas de Documento',
    key: adminConfigPath + "/document_folders",
    routePath: adminConfigPath + "/document_folders",
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "DocumentFoldersPage",
      import_path: "containers/documentFolders/DocumentFoldersPage"
    }
  }, {
    ordination: '01.04.03',
    name: 'Rendas',
    key: adminConfigPath + "/income",
    routePath: adminConfigPath + "/income",
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "IncomesPage",
      import_path: "containers/incomes/IncomesPage"
    }
  }, {
    ordination: '01.04.04',
    name: 'Funções',
    key: adminConfigPath + "/job_roles",
    routePath: adminConfigPath + "/job_roles",
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "JobRolesPage",
      import_path: "containers/jobRoles/JobRolesPage"
    }
  }, {
    ordination: '01.04.05',
    name: 'Feriados',
    key: adminConfigPath + "/holidays",
    routePath: adminConfigPath + "/holidays",
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "HolidaysPage",
      import_path: "containers/holidays/HolidaysPage"
    }
  }, {
    ordination: '01.04.06',
    name: 'Perfis de Usuários',
    key: adminConfigPath + "/roles",
    routePath: adminConfigPath + "/roles",
    kind: 'page',
    status: 'active',
    authorization: 'tenant_admin',
    content: {
      page: "RolesPage",
      import_path: "containers/roles/RolesPage"
    },
    dividerAbove: true
  }, {
    ordination: '01.04.07',
    name: 'Usuários',
    key: adminConfigPath + "/tenant_users",
    routePath: adminConfigPath + "/tenant_users",
    kind: 'page',
    status: 'active',
    authorization: 'tenant_admin',
    content: {
      page: "UsersPage",
      import_path: "containers/users/UsersPage"
    }
  }, {
    ordination: '01.04.08',
    name: 'Avançado',
    key: adminAdvancedConfigPath,
    routePath: adminAdvancedConfigPath,
    kind: 'page',
    status: 'active',
    authorization: 'super_admin',
    children: adminAdvancedConfigRoutes
  }];
  const routes = [{
    ordination: '01.01',
    name: 'Dashboard',
    key: adminPath + "/dashboard",
    routePath: adminPath + "/dashboard",
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "DashboardAdminPage",
      import_path: "containers/Dashboard"
    }
  }, {
    ordination: '01.02',
    name: 'Pessoas',
    key: adminPath + "/people",
    routePath: adminPath + "/people",
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "PeoplePage",
      import_path: "containers/people/PeoplePage"
    }
  }, {
    ordination: '01.03',
    name: 'Grupo Empresarial',
    key: adminPath + "/company_group",
    routePath: adminPath + "/company_group",
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    content: {
      page: "CompaniesPage",
      import_path: "containers/companies/CompaniesPage"
    }
  }, {
    ordination: '01.04',
    name: 'Configurações',
    key: adminConfigPath,
    routePath: adminConfigPath,
    kind: 'page',
    status: 'active',
    authorization: 'regular',
    children: adminConfigRoutes,
    dividerAbove: true
  }];
  return {
    ordination: '01',
    name: 'Administração',
    key: adminPath,
    kind: 'module',
    status: 'active',
    routePath: adminPath,
    routePathParams: undefined,
    dividerAbove: false,
    icon: 'admin',
    content: undefined,
    contentProp: undefined,
    authorization: 'regular',
    userAuthorization: undefined,
    children: routes
  };
};