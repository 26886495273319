import gql from "graphql-tag";
import { reconciliationMovementFragment } from "./ReconciliationMovement";
export const reconciliationTransactionFragment = gql`
  fragment ReconciliationTransaction on ReconciliationTransaction {
    id,
    fitid,
    checkNumber,
    referenceNumber,
    description,
    name,
    datetime,
    amount,
    kind,
    status,
    movements {
      ...ReconciliationMovement
    }
  }
  ${reconciliationMovementFragment}
`;