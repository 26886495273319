import gql from "graphql-tag";
import { bankFragment } from "./Bank";
export const personBankingFragment = gql`
  fragment PersonBanking on PersonBanking {
    id,
    description,
    branch,
    kind,
    number,
    pixKey,
    bank {
      ...Bank
    },
    implantationDate
  }
  ${bankFragment}
`;