export const EMAIL = 'email';
export const PHONE = 'phone';
export const NOT_ANSWERED = 'not_answered';
export const PAYMENT_PROMISSED = 'payment_promissed';
export const PAYMENT_QUESTIONED = 'payment_questioned';
export const LEFT_A_MESSAGE = 'left_a_message';
export const PAYMENT_NEGOTIATED = 'payment_negotiated';
export const SENT_EMAIL = 'sent_email';
export const CHARGE_OPTIONS = [{
  value: NOT_ANSWERED,
  label: 'Não atendeu'
}, {
  value: PAYMENT_PROMISSED,
  label: 'Prometeu pagamento'
}, {
  value: PAYMENT_QUESTIONED,
  label: 'Questionou pagamento'
}, {
  value: LEFT_A_MESSAGE,
  label: 'Deixado recado'
}, {
  value: PAYMENT_NEGOTIATED,
  label: 'Negociou pagamento'
}, {
  value: SENT_EMAIL,
  label: 'Enviado email',
  disabled: true
}];