export const MODULE = 'module';
export const PAGE = 'page';
export const ACTION = 'action';
export const REPORTS = 'reports';
export const ACTIVE = 'active';
export const DISABLED = 'disabled';
export const NEW_RESOURCE = 'new_resource';
export const UNDER_CONSTRUCTION = 'under_construction';
export const SUPER_ADMIN = 'super_admin';
export const KIND_OPTIONS = [{
  value: MODULE,
  label: 'Módulo'
}, {
  value: PAGE,
  label: 'Página'
}, {
  value: ACTION,
  label: 'Ação'
}, {
  value: REPORTS,
  label: 'Relatório'
}];
export const STATUS_OPTIONS = [{
  value: ACTIVE,
  label: 'Ativo'
}, {
  value: DISABLED,
  label: 'Desabilitado'
}, {
  value: NEW_RESOURCE,
  label: 'Novo recurso'
}, {
  value: UNDER_CONSTRUCTION,
  label: 'Em construção'
}, {
  value: SUPER_ADMIN,
  label: 'Somente para Super Administradores'
}];