import { bind, precisionType } from 'grifo-components';
export const calculateApportionmentValue = (fieldList, indexName, form, amount) => {
  if (!form) return;
  const apportionment = form.getFieldValue([fieldList, indexName, 'apportionment']);
  const value = precisionType(amount * apportionment / 100, 2);
  bind(form, value, [fieldList, indexName, 'value']);
};
export const calculateApportionment = (fieldList, indexName, form, amount) => {
  if (!form) return;
  const value = form.getFieldValue([fieldList, indexName, 'value']);
  const apportionment = precisionType(100 * value / amount, 2);
  bind(form, apportionment, [fieldList, indexName, 'apportionment']);
};
export const calculateApportionableAmount = (fieldList, indexName, form, amount) => {
  let all = form.getFieldValue(fieldList) || [];
  let apportionments = all.map((object, index) => index !== indexName ? object?.value : 0);
  const already = precisionType(apportionments.reduce((acc, cur) => acc + cur), 2);
  return amount - already;
};
export const calculateApportionable = (fieldList, form) => {
  let all = form.getFieldValue(fieldList) || [];
  let apportionments = all.map(object => object?.apportionment);
  return precisionType(apportionments.reduce((acc, cur) => acc + cur), 2);
};