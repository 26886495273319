import gql from "graphql-tag";
import { accountSimpleFragment } from "./AccountSimple";
import { movementFragment } from "./Movement";
export const reconciliationMovementFragment = gql`
  fragment ReconciliationMovement on ReconciliationMovement {
    id,
    transactionId,
    value,
    movement {
      ...Movement
    },
    account {
      ...AccountSimple
    },
  }
  ${movementFragment}
  ${accountSimpleFragment}
`;