import { today, getLabelByValue } from 'grifo-components';
import { STATUS_KIND } from '../constants/movements/MovementStatusOptions';
export const reversingEntryParamBuilder = (movements, values) => {
  delete movements.documents64;
  return {
    movements: movements,
    date: values?.date ? values?.date.format("YYYY-MM-DD") : undefined,
    softDelete: values?.softDelete
  };
};
export const reversingEntryDefaultBuilder = record => {
  return {
    date: today(),
    id: record?.id,
    origin: record?.origin,
    transactionId: record?.transactionId,
    value: record?.balance,
    shortComplement: getLabelByValue(STATUS_KIND, record?.status)
  };
};