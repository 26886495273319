import { INSTALLMENT, IN_CASH, MONTH } from "../constants/paymentConditions/PaymentConditionsFieldsOptions";
export const paymentConditionParamBuilder = (record, values) => {
  return {
    attributes: {
      id: record ? record.id : null,
      name: values.name,
      timeKind: values.condition === IN_CASH ? null : values.timeKind,
      time: values.condition === IN_CASH ? null : values.time,
      condition: values.condition,
      installments: values.condition === IN_CASH ? null : values.installments,
      gracePeriod: values.condition === INSTALLMENT ? values.gracePeriod : null,
      fees: values.fees,
      discount: values.discount
    }
  };
};
export const paymentConditionDefaultBuilder = record => {
  const object = record || {};
  return {
    name: object.name,
    timeKind: object.timeKind || MONTH,
    time: object.time || 1,
    condition: object.condition || IN_CASH,
    installments: object.installments || 1,
    gracePeriod: object.gracePeriod || 0,
    fees: object.fees || 0,
    discount: object.discount || 0
  };
};