import { ApolloClient } from 'apollo-client';
import { withClientState } from 'apollo-link-state';
import { HttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { ApolloLink } from 'apollo-link';
import { clearSession, clearSessionData, getSessionData, setSessionData } from './SessionUtil';
import { VALIDATION, AUTH_ERROR } from '../constants/GraphQL';
import { sanitize } from './ParamsUtil';
import { notifierType, notify } from '../notifiers/Notifier';
import * as ActionCable from '@rails/actioncable';
import ActionCableLink from 'graphql-ruby-client/subscriptions/ActionCableLink';
export const clientBuilder = urlBackend => {
  const cable = ActionCable.createConsumer('http://localhost:3001/cable');
  const hasSubscriptionOperation = ({
    query: {
      definitions
    }
  }) => {
    return definitions.some(({
      kind,
      operation
    }) => kind === 'OperationDefinition' && operation === 'subscription');
  };
  const opts = {
    credentials: 'same-origin'
  };
  const apolloCache = new InMemoryCache();
  const stateLink = withClientState({
    cache: apolloCache
  });
  const httpLink = new HttpLink({
    uri: urlBackend,
    ...opts
  });
  const authMiddlewareLink = setContext(() => {
    const session = getSessionData();
    const headers = {
      headers: session ? {
        "uid": session.uid,
        "client": session.client,
        "access-token": session.accessToken
      } : {},
      ...{
        "Accept-Encoding": "gzip"
      }
    };
    return headers;
  });
  const afterwareLink = new ApolloLink((operation, forward) => forward(operation).map(response => {
    const context = operation.getContext();
    const {
      response: {
        headers
      }
    } = context;
    clearSessionData();
    if (headers) {
      setSessionData({
        uid: headers.get("uid"),
        client: headers.get("client"),
        accessToken: headers.get("access-token"),
        expiry: headers.get("expiry")
      });
    }
    return response;
  }));
  const cleanTypename = new ApolloLink((operation, forward) => {
    if (operation.variables) operation.variables = sanitize(operation.variables, ['__typename']);
    return forward(operation);
  });
  const errorLink = onError(error => {
    console.log(error);
    debugger;
    if (error.graphQLErrors) {
      if (error.graphQLErrors.length > 0) {
        const gqlError = error.graphQLErrors[0];
        if (gqlError.extensions) {
          if (gqlError.extensions.code === VALIDATION) {
            notify("Validação", error.graphQLErrors[0].message, notifierType.ALERT, true);
            return;
          } else if (gqlError.extensions.code === AUTH_ERROR) {
            clearSession();
            notify("Autenticação", "Você não está mais autenticado! Efetue login novamente.", notifierType.SUCCESS);
            return;
            // return <Navigate to='/login'/>;
          }
        } else {
          notify("Erro", "Erro do servidor: " + error.graphQLErrors[0].message, notifierType.ALERT, true);
          return;
        }
      }
      notify("Erro", "Falha na instrução GraphQL!", notifierType.ERROR);
    } else if (error.networkError) notify("Comunicação", "Falha na comunicação com o servidor! O registro não foi salvo.", notifierType.ERROR);else notify("Erro", "Erro não registrado", notifierType.ERROR);
  });
  const link = ApolloLink.from([cleanTypename, errorLink, stateLink, afterwareLink, authMiddlewareLink, httpLink]);
  const linkSubs = ApolloLink.split(hasSubscriptionOperation, new ActionCableLink({
    cable
  }), link);
  const defaultOptions = {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'ignore'
    },
    query: {
      fetchPolicy: 'network-only',
      errorPolicy: 'all'
    },
    mutate: {
      errorPolicy: 'all'
    }
  };
  return new ApolloClient({
    link: linkSubs,
    cache: apolloCache,
    withCredentials: true,
    connectToDevTools: true,
    defaultOptions
  });
};