import gql from "graphql-tag";
export const latePaymentCalculationFragment = gql`
  fragment LatePaymentCalculation on LatePaymentCalculation {
    basis,
    overdueDays,
    fineValue,
    fineRate,
    interestValue,
    interestRate,
    interestKind,
    earlyPaymentValue
  }
`;