import gql from "graphql-tag";
import { reconciliationTransactionFragment } from "./ReconciliationTransaction";
export const reconciliationFragment = gql`
  fragment Reconciliation on Reconciliation {
    id,
    bankAccountId,
    compensationCode,
    accountNumber,
    branch,
    start,
    finish,
    status,
    balance,
    transactions {
      ...ReconciliationTransaction
    }
  }
  ${reconciliationTransactionFragment}
`;