import gql from "graphql-tag";
import { fiscalCouncilFragment } from "../fragments/FiscalCouncil";
export const GQL_FISCAL_COUNCILS_LIST = gql`
  query GetAll($pageSize: Int, $cursor: String) {    
    fiscalCouncilsPaginated(first: $pageSize, after: $cursor) {
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasPreviousPage
        hasNextPage
      } 
      edges { 
        cursor
        node {
          ...FiscalCouncil
        }
      }
    }    
  }
  ${fiscalCouncilFragment}
`;
export const GQL_FISCAL_COUNCIL_STORE = gql`
  mutation StoreFiscalCouncil($attributes: FiscalCouncilAttributes!) {
    storeFiscalCouncil(input: {attributes: $attributes}) { 
        fiscalCouncil {
        ...FiscalCouncil
    }, 
      errors 
    } 
  }
  ${fiscalCouncilFragment}
`;