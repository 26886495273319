import gql from "graphql-tag";
import { resultCenterFragment } from "./ResultCenter";
export const movementResultCenterFragment = gql`
  fragment MovementResultCenter on MovementResultCenter {
    id,
    movementId,
    value,
    apportionment,
    resultCenterId,
    resultCenter{
      ...ResultCenter
    }
  }
  ${resultCenterFragment}

`;