import Cookies from "universal-cookie";
import { SUPER_ADMIN, TENANT_ADMIN } from "../constants/users/UsersFieldsOptions";
import { getSessionInfo } from "grifo-components";
export const isAuthenticated = () => {
  const session = new Cookies().get("session");
  const loginData = new Cookies().get("loginData");
  var authenticated = session && loginData;
  if (authenticated) authenticated = session.uid === loginData.email;
  if (authenticated) {
    const sessionInfo = getSessionInfo();
    authenticated = sessionInfo && sessionInfo.tenant && sessionInfo.company && sessionInfo.person;
  }
  if (authenticated) {
    const currentTime = Date.now().valueOf() / 1000;
    authenticated = currentTime <= session.expiry;
  }
  return authenticated;
};
export const setLoginData = loginData => {
  new Cookies().set("loginData", loginData);
};
export const getLoginData = () => {
  return new Cookies().get("loginData");
};
export const getUserName = () => {
  const sessionInfo = getSessionInfo();
  return sessionInfo && sessionInfo.person ? sessionInfo.person.name : "";
};
export const getUserFirstName = () => {
  const name = getUserName();
  return name ? name.split(' ')[0] : "";
};
export const getUserFirstLetter = () => {
  const name = getUserFirstName();
  return name ? name[0] : "";
};
export const isSuperAdmin = () => {
  const sessionInfo = getSessionInfo() || {};
  return sessionInfo.profile === SUPER_ADMIN;
};
export const isTenantAdmin = () => {
  const sessionInfo = getSessionInfo() || {};
  return (sessionInfo.tenantUser || {}).profile === TENANT_ADMIN;
};

// signin

// signout