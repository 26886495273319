import gql from "graphql-tag";
export const metricFragment = gql`
  fragment Metric on Metric {
    id,
    key,
    value,
    average,
    count,
    date,
  }
`;