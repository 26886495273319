import gql from "graphql-tag";
import { userFragment, userStoreFragment } from "../fragments/User";
import { userSimpleFragment } from "../fragments/UserSimple";
export const GQL_ACCESS_TENANT = gql`
  mutation AccessTenant($tenantId: ID!) {
    accessTenant(input: {tenantId: $tenantId}) {
      user {
        ...UserStore
      },
      errors
    }
  }
  ${userStoreFragment}
`;
export const GQL_ACCESS_COMPANY = gql`
  mutation AccessCompany($companyId: ID!) {
    accessCompany(input: {companyId: $companyId}) {
      user {
        ...UserStore
      },
      errors
    }
  }
  ${userStoreFragment}
`;
export const GQL_USERS_LIST = gql`
  query users($pageSize: Int, $cursor: String, $all: Boolean) {
    usersPaginated(first: $pageSize, after: $cursor, all: $all) {
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasPreviousPage
        hasNextPage
      } 
      edges { 
        cursor
        node {
          ...UserStore
        }
      }
    }    
  }
  ${userStoreFragment}
`;
export const GQL_USERS_OF_TENANT_LIST = gql`
  query users($pageSize: Int, $cursor: String, $tenantId: ID, $userFilter: UserFilter) {
    usersPaginated(first: $pageSize, after: $cursor, tenantId: $tenantId, userFilter: $userFilter) {
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasPreviousPage
        hasNextPage
      } 
      edges { 
        cursor
        node {
          ...UserStore
        }
      }
    }    
  }
  ${userStoreFragment}
`;
export const GQL_USER_INACTIVATE = gql`
  mutation InactivateUser($id: ID!) {
    inactivateUser(input: {userId: $id}) {
      id,
      discardedAt
    }
  }
`;
export const GQL_USER_RESET_PASSWORD = gql`
  mutation ResetPassword($id: ID!) {
    resetPassword(input: {userId: $id}) {
      user {
        ...User
      }
    }
  }
  ${userFragment}
`;
export const GQL_USER_STORE = gql`
  mutation StoreUser($attributes: UserAttributes!) {
    storeUser(input: {attributes: $attributes}) { 
      user {
        ...UserStore
      },
      errors 
    } 
  }
  ${userStoreFragment}
`;
export const GQL_UPDATE_PASSWORD = gql`
  mutation userUpdatePassword($password: String!, $passwordConfirmation: String!, $currentPassword: String!) {
    userUpdatePassword(password: $password, passwordConfirmation: $passwordConfirmation, currentPassword: $currentPassword) { 
      authenticatable {
        ...UserSimple
      }
    } 
  }
  ${userSimpleFragment}
`;
export const GQL_UPDATE_PASSWORD_BY_TOKEN = gql`
  mutation userUpdateToken($password: String!, $passwordConfirmation: String!, $token: String!) {
    userUpdateToken(input: {password: $password, passwordConfirmation: $passwordConfirmation, token: $token}) { 
      user { 
        ...UserSimple
      }
    } 
  }
  ${userSimpleFragment}  
`;