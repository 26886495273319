export const PRESIDENT = 'president';
export const VICE_PRESIDENT = 'vice_president';
export const CHIEF_COUNSELOR = 'chief_counselor';
export const COUNSELOR = 'counselor';
export const TREASURER = 'treasurer';
export const ALTERNATE = 'alternate';
export const SECRETARY = 'secretary';
export const ADMINISTRATIVE = 'administrative';
export const FISCAL = 'fiscal';
export const ADMINISTRATIVE_MEMBER_ROLE = [{
  value: PRESIDENT,
  label: 'Presidente'
}, {
  value: VICE_PRESIDENT,
  label: 'Vice Presidente'
}, {
  value: TREASURER,
  label: 'Tesoureiro'
}, {
  value: SECRETARY,
  label: 'Secretário'
}];
export const FISCAL_MEMBER_ROLE = [{
  value: CHIEF_COUNSELOR,
  label: 'Conselheiro Chefe'
}, {
  value: COUNSELOR,
  label: 'Conselheiro'
}, {
  value: ALTERNATE,
  label: 'Suplente'
}];
export const COUNCIL_OPTIONS = [{
  value: ADMINISTRATIVE,
  label: 'Conselho administrativo'
}, {
  value: FISCAL,
  label: 'Conselho fiscal'
}];