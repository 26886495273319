import gql from "graphql-tag";
import { addressFragment } from 'admin-front';
export const cooperativePersonFragment = gql`
  fragment CooperativePerson on CooperativePerson {
    id,
    name,
    tradeName,
    document,
    photo64,
    email,
    gender,
    birth,
    closure,
    cadunicoCode,
    nisCode,
    discardedAt,
    juridical,
    natural,
    mainAddress {
      ...Address
    }
  }
  ${addressFragment}
`;