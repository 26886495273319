import moment from 'moment';
import { getLabelByValue } from 'grifo-components';
import { HOLIDAYS_KIND_OPTIONS, HOLIDAYS_RECURRENCE_OPTIONS } from "../../constants/holidays/HolidaysFieldsOptions";
export const holidayDecorator = record => {
  record = record || {};
  return {
    facultativePoint: record.facultativePoint ? "Sim" : "Não",
    kind: getLabelByValue(HOLIDAYS_KIND_OPTIONS, record.kind),
    recurrence: getLabelByValue(HOLIDAYS_RECURRENCE_OPTIONS, record.recurrence),
    date: record.date ? moment(record.date).format("DD/MM/YYYY") : moment(record.yearlyDate).format("DD/MM/YYYY")
  };
};