import gql from "graphql-tag";
export const GQL_LOG = gql`
  query GetLog($filter: LogFilter!) {
    getLogs(filter: $filter) {
      id,
      version,
      auditedChanges,
      user,
      createdAt,
      action
    }
  }
`;