import gql from "graphql-tag";
import { personSimpleFragment } from 'admin-front';
import { AccountPaymentFragment } from "./AccountPayment";
export const accountSimpleFragment = gql`
  fragment AccountSimple on Account {
    id,
    transactionId,
    kind,
    value,
    balance,    
    date,
    dueDate,
    setup,
    dateStatus,
    dueDateStatus,
    daysEllapsed,
    daysToDue,
    installmentNumber,
    totalInstallments,
    person {
      ...PersonSimple
    },
    payments {
      ...AccountPayment
    }
  }
  ${personSimpleFragment}
  ${AccountPaymentFragment}
`;