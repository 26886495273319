export const phaseParamBuilder = (record, values) => {
  return {
    attributes: {
      attributes: values.phases.map(phase => {
        delete phase.currentEvent;
        delete phase.canOpen;
        delete phase.canReopen;
        delete phase.canClose;
        delete phase.canPause;
        delete phase.canSkip;
        return {
          ...phase
        };
      }),
      assemblyId: record.id
    }
  };
};
export const phaseDefaultBuilder = record => {
  return {
    phases: record || []
  };
};