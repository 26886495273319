import gql from "graphql-tag";
import { personFragment } from 'admin-front';
export const fiscalCouncilFragment = gql`
  fragment FiscalCouncil on FiscalCouncil  { 
    id,
    role,
    start,
    finish,
    person {
      ...Person
    }
  }
  ${personFragment}

`;