export const ADMIN = 'admin';
export const FINANCIAL = 'financial';
export const STOCK = 'stock';
export const PURCHASE = 'purchase';
export const SELL = 'sell';
export const FISCAL = 'fiscal';
export const ACQUISITION = 'acquisition';
export const ACCOUNTING = 'accounting';
export const COOPERATIVE = 'cooperative';
export const AGRICULTURE = 'agriculture';
export const FLEET = 'fleet';
export const LOGISTIC = 'logistic';
export const SERVICE = 'service';
export const INDUSTRY = 'industry';
export const INVESTMENT = 'investment';
export const HUMAN_CAPITAL = 'human_capital';
export const APPROVAL = 'approval';
export const PROPERTY = 'property';
export const REPORTS = 'reports';
export const CONFIG = 'config';
export const ADVANCED_CONFIG = 'advanced_config';