import { sanitize } from 'grifo-components';
import { MAIN } from "../constants/addresses/AddressesFieldsOptions";
const companyBuilder = values => {
  let params = sanitize(values, ["city", "contact"]);
  params.phone.naming = "main";
  params.address.naming = MAIN;
  return {
    person: {
      name: params.name,
      tradeName: params.tradeName,
      document: params.document,
      photo64: params.photo64,
      email: params.email,
      phone: params.phone,
      address: params.address,
      birth: params.birth,
      closure: params.closure
    }
  };
};
export const RegistrationBuilder = values => {
  return {
    userDocument: values.userDocument,
    userName: values.userName,
    email: values.userEmail,
    password: values.password,
    passwordConfirmation: values.passwordConfirmation,
    company: companyBuilder(values)
  };
};