import gql from "graphql-tag";
import { assemblyFragment } from "../fragments/Assembly";
import { assemblyEventFragment } from "../fragments/AssemblyEvent";
export const GQL_ASSEMBLY_EVENT_LIST = gql`
  {
    assembliesEvent { 
      ...AssemblyEvent
    }
  }
  ${assemblyEventFragment}
`;
export const GQL_ASSEMBLY_REALIZATION_LIST = gql`
  {
    assembliesRealization { 
      ...AssemblyRealization
    }
  }
  ${assemblyFragment}
`;
export const GQL_ASSEMBLY_EVENT_STORE = gql`
  mutation StoreAssemblyEvent($attributes: AssemblyEventAttributes!) {
    storeAssemblyEvent(input: {attributes: $attributes}) { 
        assemblyEvent {
        ...AssemblyEvent
    }, 
      errors 
    } 
  }
  ${assemblyEventFragment}
`;
export const GQL_ASSEMBLY_OPEN = gql`
mutation OpenAssemblyEvent($id: ID!) { 
  openAssemblyEvent(input: {id: $id}) {
    id
  }
}
`;
export const GQL_ASSEMBLY_CLOSE = gql`
mutation CloseAssemblyEvent($id: ID!) { 
  closeAssemblyEvent(input: {id: $id}) {
    id
  }
}
`;
export const GQL_MINUTES_STORE = gql`
mutation StoreMinutes($id: ID!, $minutes: String) { 
  storeMinutes(input: {id: $id, minutes: $minutes}) {
    id,
    errors
  }
}
`;