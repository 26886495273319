export const electionFinalizationParamBuilder = (record, values) => {
  return {
    attributes: {
      electionId: (record || {}).id,
      offlineElectionVotes: Object.values(values).map(object => ({
        choice: object.slateId === "0" ? 'abstained' : 'slate',
        slateId: object.slateId === "0" ? undefined : object.slateId,
        votes: object.votes
      }))
    }
  };
};
export const electionFinalizationDefaultBuilder = record => {
  return {
    slates: (record || {}).slates
  };
};