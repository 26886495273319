export { default as AllUsersPage } from './allUsers/AllUsersPage';
export { default as CompaniesPage } from './companies/CompaniesPage';
export { default as CompanyPage } from './companies/CompanyPage';
export { default as HolidaysPage } from './holidays/HolidaysPage';
export { default as HolidayPage } from './holidays/HolidayPage';
export { default as PeoplePage } from './people/PeopleListPage';
export { default as PersonPage } from './people/PersonPage';
export { default as RegistrationAgenciesPage } from './registrationAgencies/RegistrationAgenciesPage';
export { default as RegistrationAgencyPage } from './registrationAgencies/RegistrationAgencyPage';
export { default as TenantsPage } from './tenants/TenantsPage';
export { default as TenantPage } from './tenants/TenantPage';
export { default as LoginPage } from './users/LoginPage';
export { default as RegistrationPage } from './users/RegistrationPage';
export { default as ResetPasswordPage } from './users/ResetPasswordPage';
export { default as UserConfirmationPage } from './users/UserConfirmationPage';
export { default as UserAuthManagerPage } from './users/UserAuthManagerPage';
export { default as UsersPage } from './users/UsersPage';
export { default as UserPage } from './users/UserPage';
export { default as DashboardAdminPage } from './Dashboard';