import gql from "graphql-tag";
import { reportFragment } from '../fragments/Report';
export const GQL_REPORT = gql`
query search($filter: ReportFilter) {    
  reports(filter: $filter) {
    ...Report
  }
}
${reportFragment}

`;