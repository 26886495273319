import gql from "graphql-tag";
import { cooperativePersonFragment } from "./CooperativePerson";
import { addressFragment, phoneFragment } from 'admin-front';
import { organizationSimpleFragment } from "./OrganizationSimple";
export const cooperativeCompanyOrganizationFragment = gql`
  fragment CooperativeCompanyOrganization on CooperativeCompanyOrganization {
    id,
    companyId,
    kind,
    name,
    person {
      ...CooperativePerson
    },
    address {
      ...Address
    },
    phone {
      ...Phone
    },
    organization {
      ...OrganizationSimple
    }
  }
  ${cooperativePersonFragment}
  ${addressFragment}
  ${phoneFragment} 
  ${organizationSimpleFragment} 
`;