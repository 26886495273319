import gql from "graphql-tag";
import { tenantFragment } from "../fragments/Tenant";
export const GQL_TENANTS_LIST = gql`
  query tenants($pageSize: Int, $cursor: String, $tenantFilter: TenantFilter) {    
    tenantsPaginated(first: $pageSize, after: $cursor, tenantFilter: $tenantFilter) {
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasPreviousPage
        hasNextPage
      } 
      edges { 
        cursor
        node {
          ...Tenant
        }
      }
    }    
  }
  ${tenantFragment}
`;
export const GQL_TENANT_SEARCH = gql`
  query Get($text: String) {    
    tenants(text: $text) {
      ...Tenant
    }
  }
  ${tenantFragment}    
`;
export const GQL_TENANT_ID_SEARCH = gql`
  query Get($id: ID) {    
    tenants(id: $id) {
      ...Tenant
    }
  }
  ${tenantFragment}   
`;
export const GQL_TENANT_STORE = gql`
  mutation StoreUser($attributes: TenantAttributes!) {
    storeTenant(input: {attributes: $attributes}) { 
      tenant {
        ...Tenant
      },
      errors 
    } 
  }
  ${tenantFragment}
`;
export const GQL_TENANT_INACTIVATE = gql`
  mutation Inactivate($id: ID!) {
    inactivate(input: {id: $id, className: "Tenant"}) {
      id,
      discardedAt
    }
  }`;