import gql from "graphql-tag";
export const GQL_SEARCH_OPEN_CURRENT_ASSETS = gql`
  query financialDashboard($currentAssets: Boolean) {    
    financialDashboard(currentAssets: $currentAssets) { 
      currentAssets { 
        cashier{
          id,
          name,
          balance,
          kind
        },
        bank{
          id,
          name,
          balance,
          kind
        },
        treasury{
          id,
          name,
          balance,
          kind
        }
      }
    }
  }
`;
export const GQL_DAILY_REVENUE = gql`
  query financialDashboard($dailyRevenue: Boolean) {
    financialDashboard(dailyRevenue: $dailyRevenue) {   
      dailyRevenue
    }
  }
`;
export const GQL_DAILY_EXPENSES = gql`
  query financialDashboard($dailyExpenses: Boolean) {
    financialDashboard(dailyExpenses: $dailyExpenses) {   
      dailyExpenses
    }
  }
`;
export const GQL_DAILY_TRANSFER = gql`
  query financialDashboard($dailyTransfer: Boolean) {
    financialDashboard(dailyTransfer: $dailyTransfer) {   
      dailyTransfer
    }
  }
`;
export const GQL_DAILY_PAY_RECEIVE = gql`
  query financialDashboard($dailyPayReceive: Boolean) {
    financialDashboard(dailyPayReceive: $dailyPayReceive) {   
      dailyPayReceive {
        id,
        name,
        value,
        fill
      }
    }
  }
`;
export const GQL_REVENUE_FROM_ASSOCIATES = gql`
  query financialDashboard($revenueFromAssociates: BillingFromAssociatesFilter) {
    financialDashboard(revenueFromAssociates: $revenueFromAssociates) {   
      revenueFromAssociates {
        id,
        name,
        value
      }
    }
  }
`;
export const GQL_EXPENSE_FROM_ASSOCIATES = gql`
  query financialDashboard($expenseFromAssociates: BillingFromAssociatesFilter) {
    financialDashboard(expenseFromAssociates: $expenseFromAssociates) {   
      expenseFromAssociates {
        id,
        name,
        value
      }
    }
  }
`;