import { MAIN } from 'admin-front';
import { sanitize } from 'grifo-components';
import { SINGULAR } from "../constants/organizations/OrganizationFieldsOptions";
export const organizationParamBuilder = (record, values) => {
  let params = sanitize(values, ["city", "contact"]);
  const organization = record?.organization || {};
  params.phone.naming = "main";
  params.address.naming = MAIN;
  return {
    attributes: {
      id: organization ? organization.id : null,
      level: values.level,
      statutoryCapitalBase: values.statutoryCapitalBase,
      statutoryPersonAcceptance: values.statutoryPersonAcceptance,
      parentId: values.parentId,
      company: {
        id: record ? record.id : null,
        person: {
          name: params.name,
          tradeName: params.tradeName,
          document: params.document,
          photo64: params.photo64,
          email: params.email,
          phone: params.phone,
          address: params.address
        }
      }
    }
  };
};
export const organizationDefaultBuilder = record => {
  const data = record || {};
  const organization = data.organization || {};
  const person = data.person || {};
  const phone = person.phone || {};
  return {
    isOrganization: !!organization,
    name: data.name,
    level: organization.level || SINGULAR,
    statutoryCapitalBase: organization.statutoryCapitalBase || 0,
    statutoryPersonAcceptance: organization.statutoryPersonAcceptance,
    parentId: (data.parent || {}).id,
    tradeName: person.tradeName,
    dataId: data.id,
    document: person.document,
    photo64: person.photo64,
    email: person.email,
    phone: phone,
    phoneNumber: phone.number,
    phoneAreaCode: phone.areaCode,
    phoneExtension: phone.extension,
    address: data.address
  };
};