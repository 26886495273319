import gql from "graphql-tag";
import { assemblyFragment } from "../fragments/Assembly";
export const GQL_ASSEMBLIES_LIST = gql`
  query GetAll($pageSize: Int, $cursor: String, $eventFilter: EventFilter) {    
    assembliesPaginated(first: $pageSize, after: $cursor, eventFilter: $eventFilter) {
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasPreviousPage
        hasNextPage
      } 
      edges { 
        cursor
        node {
          ...Assembly
        }
      }
    }    
  }
  ${assemblyFragment}
`;
export const GQL_CLOSED_ASSEMBLIES_LIST = gql`
  query GetClosedAssemblies($pageSize: Int, $cursor: String, $eventFilter: ClosedEventFilter) {    
    closedAssembliesPaginated(first: $pageSize, after: $cursor, eventFilter: $eventFilter) {
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasPreviousPage
        hasNextPage
      } 
      edges { 
        cursor
        node {
          ...Assembly
        }
      }
    }    
  }
  ${assemblyFragment}
`;
export const GQL_OPENED_ASSEMBLIES_LIST = gql`
  query GetOpenedAssemblies($pageSize: Int, $cursor: String, $eventFilter: OpenedEventFilter) {    
    openedAssembliesPaginated(first: $pageSize, after: $cursor, eventFilter: $eventFilter) {
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasPreviousPage
        hasNextPage
      } 
      edges { 
        cursor
        node {
          ...Assembly
        }
      }
    }    
  }
  ${assemblyFragment}
`;
export const GQL_ASSEMBLY_STORE = gql`
  mutation StoreAssembly($attributes: AssemblyAttributes!) {
    storeAssembly(input: {attributes: $attributes}) { 
        assembly {
        ...Assembly
    }, 
      errors 
    } 
  }
  ${assemblyFragment}
`;
export const GQL_ASSEMBLY_INACTIVATE = gql`
  mutation Inactivate($id: ID!) { 
    inactivate(input: {id: $id, className: "Cooperative::Assembly"}) {
      id,
      discardedAt
    }
  }
`;