import gql from "graphql-tag";
import { bankAccountSimpleFragment } from "./BankAccountSimple";
import { accountSimpleFragment } from "./AccountSimple";
export const currentAssetSelectionFragment = gql`
  fragment CurrentAssetSelection on CurrentAsset  { 
    id,
    name,
    bankAccount{
      ...BankAccountSimple
    },
    account {
      ...AccountSimple
    }
  }
  ${bankAccountSimpleFragment}
  ${accountSimpleFragment}
`;