export const attendanceParamBuilder = (record, values) => {
  return {
    attributes: {
      id: record ? record.id : null,
      assemblyId: values.assemblyId,
      participantId: values.participantId
    }
  };
};
export const attendanceDefaultBuilder = record => {
  return {
    participantId: (record || {}).participantId
  };
};