import gql from "graphql-tag";
import { companyFragment } from "../fragments/Company";
export const GQL_COMPANIES_LIST = gql`
  {
    companies {
      ...Company,
      headOffice {
        ...Company,      
      },
      branches {
        ...Company,
      }      
    }    
  }
  ${companyFragment}
`;
export const GQL_ALL_COMPANIES = gql`
  {
    companies {
      ...Company,
    }    
  }
  ${companyFragment}
`;
export const GQL_COMPANIES_BY_TENANT = gql`
  query GetByTenantId($tenantId: ID) {    
    companies(tenantId: $tenantId) {
      ...Company
    }
  }
  ${companyFragment}
`;
export const GQL_COMPANY_ID_SEARCH = gql`
  query GetById($id: ID) {    
    companies(id: $id) {
      ...Company
    }
  }
  ${companyFragment}
`;
export const GQL_COMPANY_STORE = gql`
  mutation StoreCompany($attributes: CompanyAttributes!) {
    storeCompany(input: {attributes: $attributes}) { 
      company { 
        ...Company,
      }, 
      errors 
    } 
  }
  ${companyFragment}  
`;