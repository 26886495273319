import gql from "graphql-tag";
export const GQL_COUNTRIES_LIST = gql`
  query GetByName($name: String) {    
    countries(name: $name) {
      id,
      name,
    }
  }
`;
export const GQL_COUNTRY_ID_SEARCH = gql`
  query Get($id: ID) {    
    countries(id: $id) {
      id,
      name,
    }
  }
`;