import { sanitize } from 'grifo-components';
export const writeOffCompensationParamBuilder = (record, values) => {
  let documents64 = values.documents64 ? sanitize(values.documents64, ['name', 'url']) : null;
  return {
    movements: {
      accountId: record.id,
      operationId: values.operationId,
      value: values.value,
      date: values.date.format("YYYY-MM-DD"),
      shortComplement: values.shortComplement,
      longComplement: values.longComplement,
      referencedDocument: values.referencedDocument,
      documents64: documents64,
      currentAssetId: values.currentAssetId
    },
    accountId: record.id
  };
};
export const writeOffCompensationDefaultBuilder = record => {
  return {
    compensationsAccounts: []
  };
};