import gql from "graphql-tag";
import { pollTotalVoteFragment } from "./PollTotalVote";
export const pollFragment = gql`
  fragment Poll on Poll  { 
    id,
    position,
    start,
    finish,
    question,
    description,
    majorityType,
    minutes,
    currentAnswer,
    canOpen,
    canClose,
    canSkip,
    isClosed,
    isSkipped,
    discardedAt,
    hasVoted,
    isPresident,
    documents64 {
      id,
      data,
      filename,
      contentType,
      key
    },
    totalVote {
      ...PollTotalVote
    },
  }
  ${pollTotalVoteFragment}
`;