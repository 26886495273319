export const registrationAgencyParamBuilder = (record, values) => {
  return {
    attributes: {
      id: record ? record.id : null,
      name: values.name,
      acronym: values.acronym
    }
  };
};
export const registrationAgencyDefaultBuilder = record => {
  return {
    name: (record || {}).name,
    acronym: (record || {}).acronym
  };
};