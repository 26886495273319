import { STARTED } from "../constants/bankAccounts/reconciliationStatusOptions";
import { TO_PAY, TO_RECEIVE } from "../constants/operations/OperationsFieldsOptions";
import { getMomentFromStringDate } from "../utils/ManipulateOfx";
export const reconciliationParamBuilder = (record, accountInfos) => {
  let object = accountInfos || {};
  let data = record || {};
  return {
    attributes: {
      bankAccountId: data.id,
      compensationCode: (object.account || {}).BANKID,
      accountNumber: (object.account || {}).ACCTID,
      branch: (object.account || {}).BRANCHID,
      start: object.dateStart,
      finish: object.dateEnd,
      status: object.status || STARTED,
      balance: parseFloat(object.balance),
      transactions: (object.transactions || []).map(transaction => {
        return {
          fitid: transaction.FITID,
          checkNumber: transaction.CHECKNUM,
          referenceNumber: transaction.REFNUM,
          description: transaction.MEMO,
          name: transaction.NAME,
          datetime: getMomentFromStringDate(transaction.DTPOSTED),
          amount: parseFloat(transaction.TRNAMT.replace(',', '.')),
          kind: parseFloat(transaction.TRNAMT) > 0 ? TO_RECEIVE : TO_PAY,
          status: STARTED
        };
      })
    }
  };
};
export const reconciliationDefaultBuilder = record => {
  return {
    ...record
  };
};