import gql from "graphql-tag";
export const userSimpleFragment = gql`
  fragment UserSimple on User {
    id,
    email,
    name,
    document,
    profile,
    tenantId,
    createdAt,
    updatedAt,
    confirmedAt,
    discardedAt,
    lockedAt,
    resetPasswordSentAt,
    confirmationToken,
    signInCount,
    currentSignInAt,
    lastSignInAt,
    lastSignInIp,
    failedAttempts,    
  }
`;
export const userStoreSimpleFragment = gql`
  fragment UserStoreSimple on UserStore {
    id,
    email,
    name,
    document,
    profile,
    tenantId,
    createdAt,
    updatedAt,
    confirmedAt,
    discardedAt,
    lockedAt,
    confirmationToken,
    resetPasswordSentAt,
    signInCount,
    currentSignInAt,
    lastSignInAt,
    lastSignInIp,
    failedAttempts,    
  }
`;