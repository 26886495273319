import gql from "graphql-tag";
export const paymentMethodSimpleFragment = gql`
  fragment PaymentMethodSimple on PaymentMethod  {
    id,
    name,
    kind,
    fees,
    discount,
    integrationMethod,
    creditCardBrand,
    discardedAt
  }
`;