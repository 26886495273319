import gql from "graphql-tag";
import { movementFragment } from "../fragments/Movement";
export const GQL_TRANSFER_MOVEMENT = gql`
  mutation TransferMovement($movement: MovementAttributes!, $sourceCurrentAssetId: ID, $targetCurrentAssetId: ID) {
    transferMovement(input: {movement: $movement, sourceCurrentAssetId: $sourceCurrentAssetId, targetCurrentAssetId: $targetCurrentAssetId}) { 
      sourceMovement { 
        ...Movement
      },
      targetMovement { 
        ...Movement
      }, 
      errors 
    } 
  }
  ${movementFragment}  
`;