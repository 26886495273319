import { EMAIL, SENT_EMAIL } from "../constants/account/ChargeOptions";
export const chargeParamBuilder = (record, values) => {
  return {
    attributes: {
      id: record ? record.id : null,
      accountId: values.accountId,
      contactId: values.contactId,
      email: values.email,
      chargeMethod: values.chargeMethod,
      chargeAccount: values.chargeAccount,
      result: values.chargeMethod === EMAIL ? SENT_EMAIL : values.result,
      observation: values.observation,
      returnDate: values.returnDate ? values.returnDate.format("YYYY-MM-DD") : undefined
    }
  };
};
export const chargeDefaultBuilder = record => {
  const object = record || {};
  return {
    userId: (object.user || {}).id,
    contactId: (object.contact || {}).id,
    accountId: (object.account || {}).id,
    email: object.email,
    result: object.result,
    datetime: dateOrToday(object.datetime),
    returnDate: dateOrToday(object.returnDate)
  };
};