import gql from "graphql-tag";
import { electionSlateFragment } from "../fragments/ElectionSlate";
export const GQL_ELECTION_SLATES_LIST = gql`
  {
    electionSlate { 
      ...ElectionSlate
    }
  }
  ${electionSlateFragment}
`;
export const GQL_ELECTION_SLATE_STORE = gql`
  mutation StoreElectionSlate($attributes: ElectionSlateAttributes!) {
    storeElectionSlate(input: {attributes: $attributes}) { 
      electionSlate {
        ...ElectionSlate
    }, 
      errors 
    } 
  }
  ${electionSlateFragment}
`;