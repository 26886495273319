export const AGREE = 'agree';
export const DISAGREE = 'disagree';
export const ABSTAIN = 'abstain';
export const OPENED = 'opened';
export const CLOSED = 'closed';
export const AWAITING = 'awaiting';
export const SKIPPED = 'skipped';
export const POLL_EVENTS = [{
  value: AGREE,
  label: 'Concordar'
}, {
  value: DISAGREE,
  label: 'Discordar'
}, {
  value: ABSTAIN,
  label: 'Abster-se'
}, {
  value: OPENED,
  label: 'Iniciar'
}, {
  value: CLOSED,
  label: 'Finalizar'
}, {
  value: AWAITING,
  label: 'Pausar'
}, {
  value: SKIPPED,
  label: 'Pular'
}];