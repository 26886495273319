export const PRESIDENT = 'president';
export const VICE_PRESIDENT = 'vice_president';
export const COUNSELOR = 'counselor';
export const DIRECTOR = 'director';
export const SECRETARY = 'secretary';
export const TREASURER = 'treasurer';
export const FISCAL_COUNCIL = 'fiscal_council';
export const ASSOCIATE = 'associate';
export const INVITED = 'invited';
export const CLIENT = 'client';
export const SUPPLIER = 'supplier';
export const NORMAL = 'normal';
export const PARTICIPANT_ROLE = [{
  value: PRESIDENT,
  label: 'Presidente'
}, {
  value: VICE_PRESIDENT,
  label: 'Vice Presidente'
}, {
  value: COUNSELOR,
  label: 'Conselheiro'
}, {
  value: DIRECTOR,
  label: 'Diretor'
}, {
  value: SECRETARY,
  label: 'Secretário'
}, {
  value: TREASURER,
  label: 'Tesoureiro'
}, {
  value: FISCAL_COUNCIL,
  label: 'Conselho Fiscal'
}, {
  value: ASSOCIATE,
  label: 'Associado'
}, {
  value: INVITED,
  label: 'Convidado'
}, {
  value: CLIENT,
  label: 'Cliente'
}, {
  value: SUPPLIER,
  label: 'Fornecedor'
}, {
  value: NORMAL,
  label: 'Normal'
}];