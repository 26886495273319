import gql from "graphql-tag";
import { companyFragment } from 'admin-front';
import { assemblyEventFragment } from "./AssemblyEvent";
import { participantFragment } from "./Participant";
import { phaseFragment } from "./Phase";
export const assemblyFragment = gql`
  fragment Assembly on Assembly  { 
    id
    start,
    finish,
    kind,
    link,
    minutes,
    name,
    description,
    discardedAt,
    canOpen,
    canClose,
    closed,
    isPresident,
    eventMinutes,
    documents64 {
      id,
      data,
      filename,
      contentType,
      key
    },
    company {
      ...Company
    },
    participants {
      ...Participant
    },
    phases {
      ...Phase
    },
    assemblyEvents {
      ...AssemblyEvent
    },
    currentEvent {
      ...AssemblyEvent
    }
  }
${phaseFragment}
${companyFragment}
${participantFragment}
${assemblyEventFragment}
`;