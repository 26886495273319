import moment from 'moment';
import { INSTALLMENT, INSTALLMENT_WITH_INGRESS, IN_CASH } from '../constants/paymentConditions/PaymentConditionsFieldsOptions';
export const buildInstallments = (form, installment, onCreate) => {
  const installmentDefined = installment || form.getFieldsValue().installment || 1;
  const capital = form.getFieldsValue().value;
  const amount = form.getFieldsValue().amount;
  const competencyDate = form.getFieldsValue().competencyDate;
  const timeSpan = form.getFieldsValue().time || 1;
  const timeKind = form.getFieldsValue().timeKind || "month";
  const gracePeriod = form.getFieldsValue().gracePeriod || 0;
  const condition = form.getFieldsValue().condition || IN_CASH;
  onCreate({
    installments: installmentsBuilder(installmentDefined, capital, competencyDate, timeSpan, timeKind, gracePeriod, amount, condition)
  });
};
export const installmentsBuilder = (installmentDefined, capital, firstDate, timeSpan, timeKind, gracePeriod, amount, condition) => {
  let installment = installmentDefined || 1;
  if (condition === INSTALLMENT_WITH_INGRESS) {
    installment++;
  }
  const value = Math.round((capital / installment + Number.EPSILON) * 100) / 100;
  let installmentAmount = Math.round((amount / installment + Number.EPSILON) * 100) / 100;
  //Round edge cases. Difference goes to last installment
  const ajustedValue = Math.round((value * installment - capital + Number.EPSILON) * 100) / 100;
  const ajustedAmount = Math.round((installmentAmount * installment - amount + Number.EPSILON) * 100) / 100;
  let installments = [];
  let previousDueDate = firstDate;
  for (var i = 1; i <= installment; i++) {
    let dueDate = moment(previousDueDate).add(timeSpan, timeKind);
    if (i === 1 && condition != INSTALLMENT) {
      if (firstDate) dueDate = moment(firstDate);
      if (gracePeriod > 0) dueDate = moment(firstDate).add(gracePeriod, 'days');
    }
    let installmentValue = value;
    if (ajustedValue !== 0 && i === installment) installmentValue = installmentValue - ajustedValue;
    if (ajustedAmount !== 0 && i === installment) installmentAmount = installmentAmount - ajustedAmount;
    let difference = installmentAmount - installmentValue;
    let fees = 0;
    let discount = 0;
    difference < 0 ? fees = difference * -1 : discount = difference;
    installments.push({
      dueDate: dueDate,
      value: installmentValue,
      amount: installmentAmount,
      fees: Math.round(fees * 100) / 100,
      discount: Math.round(discount * 100) / 100
    });
    previousDueDate = dueDate;
  }
  return installments;
};