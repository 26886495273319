import gql from "graphql-tag";
import { electionEventFragment } from "./ElectionEvent";
import { electionSlateFragment } from "./ElectionSlate";
import { electionTotalFragment } from "./ElectionTotal";
import { slateFragment } from "./Slate";
export const electionFragment = gql`
  fragment Election on Election  { 
    id,
    start,
    finish,
    closed,
    councilType,
    canOpen,
    canClose,
    discardedAt,
    hasVoted,
    president,
    electionTotal {
      ...ElectionTotal
    }
    electionSlates {
      ...ElectionSlate
    },
    electionEvents {
      ...ElectionEvent
    },
    currentEvent {
      ...ElectionEvent
    }
    slates {
      ...Slate
    }
  }
  ${electionTotalFragment}
  ${electionSlateFragment}
  ${electionEventFragment}
  ${slateFragment}
`;