export const GENERATE_ALL_BANK_SLIPS = 'generate_all';
export const GENERATE_FIRST = 'generate_first';
export const NOT_GENERATE = 'not_generate';
export const OPENED = '0';
export const CLOSED = '1';
export const BANK_SLIP_PRINT_OPTIONS = [{
  value: GENERATE_ALL_BANK_SLIPS,
  label: 'Gerar para todos os pagamentos'
}, {
  value: GENERATE_FIRST,
  label: 'Gerar apenas para o primeiro pagamento'
}, {
  value: NOT_GENERATE,
  label: 'Não gerar os boletos agora'
}];
export const ACCOUNT_STATUS_OPTIONS = [{
  value: OPENED,
  label: 'Abertas'
}, {
  value: CLOSED,
  label: 'Fechadas'
}];