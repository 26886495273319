import gql from "graphql-tag";
import { organizationFragment } from "./Organization";
export const organizationParentFragment = gql`
  fragment OrganizationParent on Organization {
    ...Organization
    children {
      ...Organization,
      children {
        ...Organization,
        children {
          ...Organization,
          children {
            ...Organization,
            children {
              ...Organization,
            }
          }
        }
      }
    }
  }
  ${organizationFragment} 
`;