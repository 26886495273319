import gql from "graphql-tag";
import { accountSimpleFragment } from "./AccountSimple";
export const parentAccountSimpleFragment = gql`
  fragment ParentAccountSimple on Account {
    ...AccountSimple,
    installments {
      ...AccountSimple,
    } 
  }
  ${accountSimpleFragment}
`;