export const SUPER_ADMIN = 'super_admin';
export const TENANT_ADMIN = 'tenant_admin';
export const NORMAL_USER = 'normal_user';
export const ASSOCIATED = 'associated';
export const ACCOUNTANT = 'accountant';
export const CUSTOMER = 'customer';
export const REGULAR = 'regular';
export const TENANT_PROFILE_OPTIONS = [{
  value: TENANT_ADMIN,
  label: 'Administrador'
}, {
  value: NORMAL_USER,
  label: 'Usuário'
}, {
  value: ACCOUNTANT,
  label: 'Contador'
}, {
  value: ASSOCIATED,
  label: 'Associado',
  disabled: true
}, {
  value: CUSTOMER,
  label: 'Cliente',
  disabled: true
}];
export const USER_PROFILE_OPTIONS = [{
  value: SUPER_ADMIN,
  label: 'Super Admin'
}, {
  value: REGULAR,
  label: 'Normal'
}];