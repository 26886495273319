import gql from "graphql-tag";
import { votingFragment } from "../fragments/Voting";
export const GQL_VOTINGS_LIST = gql`
  {
    Votings { 
      ...Voting
    }
  }
  ${votingFragment}
`;
export const GQL_VOTING_STORE = gql`
  mutation StoreVoting($attributes: VotingAttributes!) {
    storeVoting(input: {attributes: $attributes}) { 
        voting {
        ...Voting
    }, 
      errors 
    } 
  }
  ${votingFragment}
`;