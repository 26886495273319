import gql from "graphql-tag";
import { holidayFragment } from "../fragments/Holiday";
export const GQL_HOLIDAYS_LIST = gql`
  query GetAll($pageSize: Int, $cursor: String, $search: String) {    
    holidaysPaginated(first: $pageSize, after: $cursor, search: $search) {
      totalCount
      pageInfo {
        endCursor
        startCursor
        hasPreviousPage
        hasNextPage
      } 
      edges { 
        cursor
        node {
          ...Holiday
        }
      }
    }    
  }
  ${holidayFragment}
`;
export const GQL_HOLIDAY_INACTIVATE = gql`
  mutation Inactivate($id: ID!) {
    inactivate(input: {id: $id, className: "Holiday"}) {
      id,
      discardedAt 
    }
  }
`;
export const GQL_HOLIDAY_STORE = gql`
  mutation StoreHoliday($attributes: HolidayAttributes!) {
    storeHoliday(input: {attributes: $attributes}) { 
      holiday { 
        ...Holiday,
      }, 
      errors 
    } 
  }
  ${holidayFragment}  
`;