export const tenantParamBuilder = (record, values) => {
  return {
    attributes: {
      id: record ? record.id : null,
      name: values.name,
      schemaName: record.schemaName
    }
  };
};
export const tenantDefaultBuilder = record => {
  let object = record || {};
  return {
    name: object.name,
    schemaName: object.schemaName
  };
};